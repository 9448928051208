export default {
  folders: [],
  currentFolderPagination: {
    offset: 0,
    limit: 10,
    total: undefined,
    currentPage: undefined,
    totalPages: undefined,
  },
  currentFolder: {
    id: undefined,
    name: undefined,
    views: [],
  },
};
