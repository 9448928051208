import * as types from './types';

export default {
  // set slips in the state
  [types.SET_SLIPS](state, slips) {
    state.slips = slips;
  },
  // Remove slip in the state
  [types.DELETE_SLIP](state, slipId) {
    state.slips = state.slips.filter(
      (slip) => slip.id !== slipId,
    );
  },
  // Set pagination in the state
  [types.SET_PAGINATION](state, paginationData) {
    state.pagination = paginationData;
  },

  [types.SET_STATUSES_COUNT](state, statusesCount) {
    state.nbSlipsFinalized = statusesCount.nbSlipsFinalized;
    state.nbSlipsToValidate = statusesCount.nbSlipsToValidate;
    state.nbDestructibleDocs = statusesCount.nbDestructibleDocs;
  },
  [types.SET_LIMIT](state, limit) {
    state.pagination.limit = limit;
  },
};
