import * as types from './types';

export default {
  // Set documents types in the state
  [types.SET_DOCUMENT_TYPES](state, documentTypes) {
    state.documentTypes = documentTypes;
  },
  // Add document types
  [types.CREATE_DOCUMENT_TYPE](state, document) {
    state.documents.unshift(document);
  },
  // Delete a specific document types from state
  [types.DELETE_DOCUMENT_TYPE](state, documentTypeId) {
    state.documentTypes = state.documentTypes.filter(
      (documentType) => documentType.id !== documentTypeId,
    );
  },
  // Update document in the state
  [types.UPDATE_DOCUMENT_TYPE](state, documentType) {
    const index = state.documentTypes.findIndex((item) => item.id === documentType.id);
    if (index !== -1) {
      state.documentTypes.splice(index, 1, documentType);
    }
  },
  // Set pagination in the state
  [types.SET_PAGINATION](state, paginationData) {
    state.pagination = paginationData;
  },
  // Set a document type in the state
  [types.SET_DOCUMENT_TYPE](state, documentType) {
    state.documentType = documentType;
  },
  // Add a document type in state
  [types.CREATE_DOCUMENT_TYPE](state, documentType) {
    state.documentTypes.unshift(documentType);
  },
  // Set document type's metadatas in the state
  [types.SET_DOCUMENT_TYPE_METADADAS](state, documentTypeMetadatas) {
    state.documentTypeMetadatas.push(documentTypeMetadatas);
    state.documentTypeMetadatas.sort((a, b) => a.order - b.order);
  },
  // Remove a specific document type metadata from state
  [types.REMOVE_DOCUMENT_TYPE_METADATA](state, id) {
    const currentData = state.documentTypeMetadatas.filter((item) => item.id === id)[0];
    state.documentTypeMetadatas.splice(state.documentTypeMetadatas.indexOf(currentData), 1);
  },
  // Reset document type metadatas state
  [types.RESET_DOCUMENT_TYPE_STATE](state) {
    state.documentTypeMetadatas = [];
  },
  [types.SET_LIMIT](state, limit) {
    state.pagination.limit = limit;
  },
};
