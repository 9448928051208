import { HTTP } from '@/http/index';

export default {
  findAll(params) {
    return HTTP.get('/metadatas', { params });
  },
  create(metadata) {
    const alertMessages = {
      errorKey: 'alert.errors.createMetadata',
      successKey: 'alert.success.createMetadata',
    };
    return HTTP.post('/metadatas', metadata, { alertMessages });
  },
  destroy(metadataId) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteMetadata',
      successKey: 'alert.success.deleteMetadata',
    };
    return HTTP.delete(`/metadatas/${metadataId}`, { alertMessages });
  },
  findById(metadataId) {
    return HTTP.get(`/metadatas/${metadataId}`);
  },
  update(metadata) {
    const alertMessages = {
      errorKey: 'alert.errors.updateMetadata',
      successKey: 'alert.success.updateMetadata',
    };
    return HTTP.put(`/metadatas/${metadata.id}`, metadata, { alertMessages });
  },
  getMetadataFiltered({ filterObject, query }) {
    return HTTP.post('/metadatas/search', filterObject, { params: query });
  },
};
