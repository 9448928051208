import ConversionRuleApi from '@/api/ConversionRuleApi';
import * as types from './types';

export default {
  // Get all conversionRules
  getConversionRules({ commit }) {
    return ConversionRuleApi.findAll().then((response) => {
      commit(types.SET_CONVERSION_RULES, response.data);
      // commit(types.SET_PAGINATION, response.data.pagination);
      return response.data;
    });
  },
  deleteConversionRule({ commit }, conversionRuleId) {
    return ConversionRuleApi.destroy(conversionRuleId).then((response) => {
      commit(types.DELETE_CONVERSION_RULES, conversionRuleId);
      return response.data;
    });
  },
  getFilesConverter({ commit }) {
    return ConversionRuleApi.getFilesConverter().then((response) => {
      commit(types.SET_FILES_CONVERTER, response.data);
      return response.data;
    });
  },
  getFileFormats({ commit }) {
    return ConversionRuleApi.getFileFormats().then((response) => {
      commit(types.SET_FILE_FORMATS, response.data);
      return response.data;
    });
  },
  createConversionRule(context, conversionRule) {
    return ConversionRuleApi.create(conversionRule).then((response) => response);
  },
};
