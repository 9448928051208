import { HTTP } from '@/http/index';

export default {
  findAll(params) {
    return HTTP.get('/bordereaus', { params });
  },
  destroy(slipId) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteSlip',
      successKey: 'alert.success.deleteSlip',
    };
    return HTTP.post(`/bordereaus/${slipId}/cancel`, { alertMessages });
  },
  validate(slipId, slipValidate) {
    return HTTP.post(`/bordereaus/${slipId}/validate`, slipValidate);
  },
  generateSlip(data) {
    return HTTP.post('/bordereaus', data);
  },
  documents(slipId) {
    return HTTP.get(
      `/bordereaus/${slipId}/documents`,
    );
  },
  findById(slipId) {
    return HTTP.get(
      `/bordereaus/${slipId}`,
    );
  },
  downloadCR(slipId) {
    return HTTP.get(`/bordereaus/${slipId}/cr`, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    });
  },
  downloadSlipRestitutionCR(slipId) {
    return HTTP.get(`/bordereaus/${slipId}/restitution-cr`, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    });
  },
  downloadSlipEliminationCR(slipId) {
    return HTTP.get(`/bordereaus/${slipId}/elimination-cr`, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    });
  },
  exportAsFNTC(slipId) {
    return HTTP.post(`/bordereaus/${slipId}/export/fntc`, {}, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    });
  },
  deleteSlipRestitutionArchive(slipId) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteRestitutionArchive',
      successKey: 'alert.success.deleteRestitutionArchive',
    };
    return HTTP.delete(`/bordereaus/${slipId}/restitution-fntc`, { alertMessages });
  },
  downloadSlipRestitutionArchive(slipId) {
    return HTTP.get(`/bordereaus/${slipId}/restitution-fntc`, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    });
  },
};
