import { HTTP } from '@/http/index';

export default {
  findAll(params) {
    return HTTP.get('/archival-spaces', { params });
  },
  create(safeBox) {
    const alertMessages = {
      errorKey: 'alert.errors.createSafeBox',
      successKey: 'alert.success.createSafeBox',
    };
    return HTTP.post('/archival-spaces', safeBox, { alertMessages });
  },
  addUser(data) {
    return HTTP.post(`/users/${data.userId}/roles`, data.safeboxUser);
  },
  updateUser(data) {
    return HTTP.put(`/users/${data.userId}/roles/${data.safeboxUser.perimeterId}`, data.safeboxUser);
  },
  destroyUserToSafebox(user) {
    let alertMessages = {
      errorKey: 'alert.errors.deleteUser',
      successKey: 'alert.success.deleteUser',
    };
    if (user.action === 'deleted') {
      alertMessages = {};
    }
    // eslint-disable-next-line no-param-reassign
    delete user.action;
    return HTTP.delete(`/users/${user.userId}/roles/${user.perimeterId}`, { alertMessages });
  },
  update(safebox) {
    const alertMessages = {
      errorKey: 'alert.errors.updateSafeBox',
      successKey: 'alert.success.updateSafeBox',
    };
    return HTTP.put(`/archival-spaces/${safebox.id}`, safebox, { alertMessages });
  },
  findById(spaceId) {
    return HTTP.get(`/archival-spaces/${spaceId}`);
  },
  freeze(archivalSpaceId) {
    return HTTP.post(`/archival-spaces/${archivalSpaceId}/freeze`);
  },
  unfreeze(archivalSpaceId) {
    return HTTP.post(`/archival-spaces/${archivalSpaceId}/unfreeze`);
  },
  reEncryptAes(cipher) {
    return HTTP.put('/archival-spaces/cipher-key', cipher);
  },
  getEncryptionProgression() {
    return HTTP.get('/archival-spaces');
  },
};
