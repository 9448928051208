import download from 'downloadjs';
import SlipApi from '@/api/SlipApi';
import DocumentApi from '@/api/DocumentApi';
import * as types from './types';

export default {
  countSlipsByStatus({ commit }) {
    const promises = [
      SlipApi.findAll({ offset: 0, limit: 1, status: 'VALIDE' }),
      SlipApi.findAll({ offset: 0, limit: 1, status: 'A_VALIDER' }),
      DocumentApi.countDestructible({ offset: 0, limit: 1 }),
    ];

    Promise.all(promises).then((
      [responseFinalizedSlips,
        responseNeedsValidationSlips,
        responseDestructibleDocs],
    ) => {
      const numberState = {
        nbSlipsToValidate: responseNeedsValidationSlips.data.pagination.total,
        nbSlipsFinalized: responseFinalizedSlips.data.pagination.total,
        nbDestructibleDocs: responseDestructibleDocs.data.data.length,
      };

      commit(types.SET_STATUSES_COUNT, numberState);
    });

    /*
    return SlipApi.findAll(params).then((response) => {
      const nbSlipsFinalized = response.data.pagination.total;
      params.status = 'A_VALIDER';
      return SlipApi.findAll(params).then((response2) => {
        const nbSlipsToValidate = response2.data.pagination.total;
        const numberState = {
          nbSlipsToValidate:
          nbSlipsFinalized,
        };
        commit(types.SET_STATUSES_COUNT, numberState);
        return response.data;
      });
    });
    */
  },
  // Get all slips
  getSlips({ commit }, params) {
    return SlipApi.findAll(params).then((response) => {
      commit(types.SET_SLIPS, response.data.data);
      commit(types.SET_PAGINATION, response.data.pagination);
      return response.data;
    });
  },
  deleteSlip({ commit }, slipId) {
    return SlipApi.destroy(slipId).then((response) => {
      commit(types.DELETE_SLIP, slipId);
      return response.data;
    });
  },
  slipValidate(context, { slipId, slipValidate }) {
    return SlipApi.validate(slipId, slipValidate).then((response) => response.data);
  },
  generateSlip(context, slipData) {
    return SlipApi.generateSlip(slipData).then((response) => response.data);
  },
  getSlipDetail(context, slipId) {
    return SlipApi.findById(slipId);
  },
  getSlipDocuments(context, slipId) {
    return SlipApi.documents(slipId);
  },
  downloadSlipCR(context, param) {
    return SlipApi.downloadCR(param.id);
  },
  downloadSlipRestitutionCR(context, param) {
    return SlipApi.downloadSlipRestitutionCR(param.id);
  },
  downloadSlipEliminationCR(context, param) {
    return SlipApi.downloadSlipEliminationCR(param.id);
  },
  exportSlipAsFNTC(context, param) {
    return SlipApi.exportAsFNTC(param.id).then((response) => {
      download(response.data, `export-FNTC-sae-${param.name}.zip`);
    });
  },
  deleteSlipRestitutionArchive(context, slipId) {
    return SlipApi.deleteSlipRestitutionArchive(slipId);
  },
  downloadSlipRestitutionArchive(context, param) {
    return SlipApi.downloadSlipRestitutionArchive(param.id);
  },
};
