<template>
  <EuiLayout class="sae-default-layout">
    <EuiSimpleLoader :is-loading="isLoading"/>
    <template v-slot:header>
      <EuiHeader>
        <template v-slot:slot-right>
          <div class="eui-u-flex">
            <EuiTooltip :content="$t('navigation.help.tooltip')" placement="top">
              <template slot="anchor">
                <EuiButton iconOnly size="large" @click="goToHelp">
                  <EuiIcon name="help"/>
                </EuiButton>
              </template>
            </EuiTooltip>
            <EuiDropdownMenu v-if="$can('LIST', 'DOCUMENT_TYPE') ||
            $can('LIST', 'CONVERSION_RULE') ||
            $can('LIST', 'METADATA') ||
            $can('LIST', 'FILE_FORMAT') ||
            ($can('UPDATE', 'USER') &&
             selectedRole.perimeterType !== 'Domain')">
              <template v-slot:anchor>
                <EuiButton iconOnly size="large">
                  <EuiIcon name="setting"/>
                </EuiButton>
              </template>
              <EuiList>
                <EuiListItem v-if="$can('LIST', 'DOCUMENT_TYPE')"
                             :button="true" @click="$router.push({ name: 'DocumentTypes' })">
                  {{ $t('header.settings.documents') }}
                </EuiListItem>
                <EuiListItem v-if="$can('LIST', 'CONVERSION_RULE')"
                             :button="true"
                             @click="$router.push({ name: 'ConversionRules' })">
                  {{ $t('header.settings.conversionRules') }}
                </EuiListItem>
                <EuiListItem v-if="$can('LIST', 'METADATA')"
                             :button="true" @click="$router.push({ name: 'MetadatasPage' })">
                  {{ $t('header.settings.metadata') }}
                </EuiListItem>
                <EuiListItem v-if="$can('LIST', 'FILE_FORMAT')"
                             :button="true"
                             @click="$router.push({ name: 'DocumentFormats' })">
                  {{ $t('header.settings.fileFormats') }}
                </EuiListItem>
                <EuiListItem v-if="selectedRole.perimeterId.slice(0, 2) === 'dm'"
                             :button="true"
                             @click="$router.push({ name: 'AdvancedParameters' })">
                  {{ $t('header.settings.advancedParameters') }}
                </EuiListItem>
                <EuiListItem v-if="selectedRole.perimeterId.slice(0, 2) === 'as'" :button="true"
                             @click="$router.push({ name: 'AdvancedOperations' })">
                  {{ $t('header.settings.advancedOperations') }}
                </EuiListItem>
              </EuiList>
            </EuiDropdownMenu>
          <span class="mr-4"></span>
            <EuiDropdownMenu>
              <template v-slot:anchor>
                <EuiButton iconOnly size="large">
                  <EuiIcon name="person"/>
                </EuiButton>
              </template>
              <EuiList>
                <EuiListItem :button="true" @click="$router.push({ name: 'profiles' })">
                  {{ $t('button.profile') }}
                </EuiListItem>
                <EuiListItem :button="true" @click="showSelectRoleDialog = true">
                  {{ $t('header.userOptions.role') }}
                </EuiListItem>
                <EuiDivider secondary/>
                <EuiListItem :button="true" @click="logout">
                  {{ $t('button.logout') }}
                </EuiListItem>
              </EuiList>
            </EuiDropdownMenu>
          </div>
        </template>
      </EuiHeader>
      <EuiHeaderMobile @menu="isSidenavOpen = !isSidenavOpen">
        <EuiDropdownMenu>
          <template v-slot:anchor>
            <EuiButton iconOnly size="large">
              <EuiIcon name="person"/>
            </EuiButton>
          </template>
          <EuiList>
            <EuiList>
              <EuiListItem :button="true" disabled @click="$router.push({ name: '' })">
                {{ $t('button.profile') }}
              </EuiListItem>
              <EuiListItem :button="true" @click="showSelectRoleDialog = true">
                {{ $t('header.userOptions.role') }}
              </EuiListItem>
              <EuiDivider secondary/>
              <EuiListItem :button="true" @click="logout">
                {{ $t('button.logout') }}
              </EuiListItem>
            </EuiList>
          </EuiList>
        </EuiDropdownMenu>
      </EuiHeaderMobile>
      <EuiBreadcrumb v-if="$route.name === 'ViewDetail'">
        <EuiBreadcrumbItem
          @click="$router.push({ name: 'DashboardPage' })">
          <EuiIcon name="home"/>{{ $t('navigation.home') }}
          <template> >  </template>
        </EuiBreadcrumbItem>
        <EuiBreadcrumbItem @click="$router.push({ name: 'ViewsPage' })">
          {{ $t('navigation.folders') }}
          <template> >  </template>
        </EuiBreadcrumbItem>
        <EuiBreadcrumbItem>
          {{ currentView.folderName }}
          <template> >  </template>
        </EuiBreadcrumbItem>
        <EuiBreadcrumbItem selected>
          {{ currentView.name }}
        </EuiBreadcrumbItem>
      </EuiBreadcrumb>
      <EuiBreadcrumb v-if="$route.name === 'FolderDetail'">
        <EuiBreadcrumbItem
          @click="$router.push({ name: 'DashboardPage' })">
          <EuiIcon name="home"/>{{ $t('navigation.home') }}
          <template> >  </template>
        </EuiBreadcrumbItem>
        <EuiBreadcrumbItem>
          {{ currentFolder.name }}
          <template> >  </template>
        </EuiBreadcrumbItem>
        <EuiBreadcrumbItem selected>
          {{ $t('navigation.displayAll') }}
        </EuiBreadcrumbItem>
      </EuiBreadcrumb>
      <EuiBreadcrumb v-else>
        <EuiBreadcrumbItem v-for="(breadcrumb, index) in breadcrumbs" :key="index"
                           :selected="index ===  breadcrumbs.length - 1"
                           @click="goToLink(breadcrumb)">
          <EuiIcon v-if="index === 0" :name="breadcrumb.icon"/>
          {{ typeof breadcrumb.name === 'object' ? breadcrumb.name[1]: breadcrumb.name }}
          <template v-if="index !==  breadcrumbs.length - 1"> >   </template>
        </EuiBreadcrumbItem>
      </EuiBreadcrumb>
    </template>
    <template v-slot:sidenav>
      <EuiSidenav v-model="isSidenavOpen"
                  :modal="isMobileView"
                  v-bind:miniVariant.sync="isMiniVariant">
        <router-link to="/">
          <EuiSidenavHeader>
            <img v-if="!isMiniVariant"
                 alt="SAE" style="height: 50px;"
                 src="@/assets/img/logoSAE.svg">
            <img v-else style="height: 50px;"  src="@/assets/img/logoSAE_mini.svg" alt="SAE">
          </EuiSidenavHeader>
        </router-link>
        <component :is="dynamicNavigation">
          <component :is="dynamicNavigationItem" :to="{ name: 'DashboardPage'}">
            <template v-slot:graphic>
              <EuiIcon size="medium" name="home"/>
            </template>
            {{ $t('navigation.home') }}
          </component>
          <component :is="dynamicNavigationItem" to="/administrators"
                     v-if="$can('LIST', 'USERS') &&
                     selectedRole.perimeterId.slice(0, 2) === 'dm'">
            <template v-slot:graphic>
              <EuiIcon size="medium" name="group"/>
            </template>
            {{ $t('navigation.administrators') }}
          </component>
          <component :is="dynamicNavigationItem" to="/safe-boxes"
                     v-if="$can('LIST', 'ARCHIVAL_SPACE')">
            <template v-slot:graphic>
              <EuiIcon size="medium" name="trunk"/>
            </template>
            {{ $t('navigation.safebox') }}
          </component>
          <component :is="dynamicNavigationSection" to="/folders"
                     @open="$router.push({ name: 'ViewsPage' })"
                     v-if="$can('LIST', 'DOCUMENT_VIEW_FOLDER')">
              <template v-slot:header>
                <EuiIcon size="medium" name="folder"/>
                {{ $t('navigation.folders') }}
              </template>
              <EuiNavigationItem disc :to="{ name: 'FoldersPage'}">
                {{ $t('navigation.allFolders') }}
              </EuiNavigationItem>
              <div v-for="(folder, index) in groupedViews" :key="index">
                <EuiNavigationItemSection disc>
                  <template v-slot:header>{{ folder.name }}</template>
                   <EuiNavigationItem disc :to="'/folders/' + folder.id">
                    {{ $t('navigation.displayAll') }}
                  </EuiNavigationItem>
                  <EuiNavigationItem v-for="(view, index) in folder.views"
                      disc :to="'/views/' + view.id" :key="index">
                    {{ view.name }}
                  </EuiNavigationItem>
                </EuiNavigationItemSection>
              </div>
          </component>
          <component :is="dynamicNavigationSection" to="/logs"
                     v-if="$can('READ', 'JOURNAL')">
            <template v-slot:header>
              <EuiIcon size="medium" name="logs"/>
              {{ $t('navigation.logs') }}
            </template>
            <EuiNavigationItem disc :to="{ name: 'LogsFilter'}">
              {{ $t('navigation.logsEntry') }}
            </EuiNavigationItem>
            <EuiNavigationItem disc :to="{ name: 'LogFiles'}">
              {{ $t('navigation.logsFile') }}
            </EuiNavigationItem>
          </component>
          <component :is="dynamicNavigationSection" :to="{ name: 'NeedsValidationSlips'}"
            v-if="$can('LIST', 'BORDEREAU')">
            <template v-slot:header>
              <EuiIcon size="medium" name="slip"/>
              {{ $t('navigation.slips') }}
            </template>
            <EuiNavigationItem disc :to="{ name: 'NeedsValidationSlips'}">
              {{ $t('navigation.toBeValidated') }}
              <template v-slot:meta>
                <EuiBadge :content="nbSlipsToValidate" color="error"/>
              </template>
            </EuiNavigationItem>
            <EuiNavigationItem disc :to="{ name: 'FinalizedSlips'}">
              {{ $t('navigation.finalized') }}
              <template v-slot:meta>
                <EuiBadge :content="nbSlipsFinalized" color="success"/>
              </template>
            </EuiNavigationItem>
          </component>
          <component :is="dynamicNavigationItem" :to="{ name: 'DestructibleDocs'}"
            v-if="$can('LIST_DESTRUCTIBLE', 'DOCUMENT')">
            <template v-slot:graphic>
              <EuiIcon size="medium" name="delete"/>
            </template>
            {{ $t('navigation.destructibleDocs') }}
            <template v-slot:meta>
              <EuiBadge :content="nbDestructibleDocs" color="error"/>
            </template>
          </component>
          <component :is="dynamicNavigationItem" to="/users"
                     v-if="$can('READ', 'USER') &&
                     selectedRole.perimeterId.slice(0, 2) !== 'dm'">
            <template v-slot:graphic>
              <EuiIcon size="medium" name="group"/>
            </template>
            {{ $t('navigation.users') }}
          </component>
          <component :is="dynamicNavigationItem" to="/archival-spaces/users"
                     v-if="$can('READ', 'USER') &&
                     (selectedRole.perimeterId.slice(0, 2) === 'dm')">
            <template v-slot:graphic>
              <EuiIcon size="medium" name="group"/>
            </template>
            {{ $t('navigation.users') }}
          </component>
        </component>
      </EuiSidenav>
    </template>
    <div class="sae-alert">
      <template v-for="alert in alerts">
        <EuiAlert  :color="alert.type" @click:close="closeAlert(alert.id)"
                   v-if="alert.isVisible" :key="alert.id" :closable="isClosable(alert.mode)"
                   @hook:mounted="autoCloseAlert(alert.mode, alert.id)">
          <EuiList>
            <EuiListItem v-for="(message, index) in alert.messages" :key="index">
              {{ message }}
            </EuiListItem>
          </EuiList>
        </EuiAlert>
      </template>
    </div>
    <SelectRoleDialog v-model="showSelectRoleDialog"/>
    <slot/>
  </EuiLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DefaultLayout',
  data() {
    return {
      isSidenavOpen: true,
      isMiniVariant: false,
      folders: [],
    };
  },
  watch: {
    isDesktopView(value) {
      this.isMiniVariant = !value;
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.application.loading.isVisible,
      alerts: (state) => state.application.alerts,
      isMobile: (state) => state.application.isMobile,
      isMobileView: (state) => state.application.responsive.xs,
      isDesktopView: (state) => state.application.responsive.lg,
      breadcrumbs() {
        return this.$route.meta.breadcrumbs ? this.$route.meta.breadcrumbs.map((item) => {
          const breadcrumb = item;
          breadcrumb.name = item.nameI18n ? this.$i18n.t(item.nameI18n) : item.name;
          breadcrumb.name = item.nameFunc ? item.nameFunc(this.$route) : item.name;
          return breadcrumb;
        }) : [];
      },
      rightMenuFolders: (state) => state.folder.folders,
      rightMenuViews: (state) => state.SAE.view.rightMenuViews,
      currentView: (state) => state.SAE.view.currentView,
      currentFolder: (state) => state.folder.currentFolder,
      nbSlipsToValidate: (state) => state.slip.nbSlipsToValidate,
      nbSlipsFinalized: (state) => state.slip.nbSlipsFinalized,
      nbDestructibleDocs: (state) => state.slip.nbDestructibleDocs,
      selectedRole: (state) => state.auth.selectedRole,
    }),
    groupedViews() {
      const folders = [];
      this.rightMenuFolders.forEach((folder) => {
        const folderStructure = {
          id: folder.id,
          name: folder.name,
          views: this.rightMenuViews.filter((view) => view.folderName === folder.name),
        };
        folders.push(folderStructure);
      });
      return folders;
    },
    showSelectRoleDialog: {
      get() {
        return this.$store.state.application.showSelectRoleDialog;
      },
      set(value) {
        if (value === true) {
          this.$store.commit('application/SHOW_SELECT_ROLE');
        } else {
          this.$store.commit('application/CLOSE_SELECT_ROLE');
        }
      },
    },
    dynamicNavigation() {
      if (this.isMiniVariant) {
        return 'EuiNavigationMini';
      }
      return 'EuiNavigation';
    },
    dynamicNavigationItem() {
      if (this.isMiniVariant) {
        return 'EuiNavigationMiniItem';
      }
      return 'EuiNavigationItem';
    },
    dynamicNavigationSection() {
      if (this.isMiniVariant) {
        return 'EuiNavigationSectionMini';
      }
      return 'EuiNavigationSection';
    },
  },
  components: {
    SelectRoleDialog: () => import(/* webpackPrefetch: true */ '@/components/dialogs/SelectRole.vue'),
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      getRightMenuViews: 'SAE/view/getRightMenuViews',
      getFolders: 'folder/getFolders',
      countSlips: 'slip/countSlipsByStatus',
      setIsMobile: 'application/setIsMobile',
    }),
    goToHelp() {
      window.location = this.$t('navigation.help.link');
    },
    onResize() {
      // eslint-disable-next-line no-unused-expressions
      this.isMobileView ? this.isSidenavOpen = false : this.isSidenavOpen = true;
    },
    closeAlert(alertId) {
      this.$store.dispatch('application/closeAlert', alertId);
    },
    autoCloseAlert(mode, alertId) {
      if (mode === 'automatic') {
        setTimeout(() => this.closeAlert(alertId), 5000);
      }
    },
    isClosable(mode) {
      return mode !== 'automatic';
    },
    goToLink(breadcrumb) {
      if (typeof breadcrumb.link === 'object') {
        this.$router.push({ name: breadcrumb.link[1] });
      } else {
        this.$router.push({ name: breadcrumb.link });
      }
    },
  },
  mounted() {
    this.isMiniVariant = !this.isDesktopView;
    this.onResize();
    this.setIsMobile();
  },
  created() {
    if (this.$can('LIST', 'DOCUMENT_VIEW_FOLDER')) {
      this.getRightMenuViews({
        limit: 1000,
        offset: 0,
      });
      this.getFolders();
    }
    window.addEventListener('resize', this.onResize);
    if (this.$can('LIST', 'BORDEREAU')) {
      this.countSlips();
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>
