import Vue from 'vue';
import VueI18n from 'vue-i18n';

function loadLocales() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  const dateTimeFormats = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).messages;
      dateTimeFormats[locale] = locales(key).dateTimeFormats;
    }
  });
  return { messages, dateTimeFormats };
}

const locales = loadLocales();

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: navigator.language.substring(0, 2),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages: locales.messages,
  dateTimeFormats: locales.dateTimeFormats,
});

export default i18n;
