import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import application from './modules/application';
import auth from './modules/auth';
import administrator from './modules/administrator';
import documentFormat from './modules/documentFormat';
import documentType from './modules/documentType';
import safebox from './modules/safebox';
import user from './modules/user';
import archivalSpaceUser from './modules/archivalSpaceUser';
import slip from './modules/slip';
import metadata from './modules/metadata';
import view from './modules/view';
import folder from './modules/folder';
import log from './modules/log';
import document from './modules/document';
import conversionRule from './modules/conversionRule';

// Plugins
import { abilityPlugin } from './plugins/ability';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export { ability } from './plugins/ability';

const store = new Vuex.Store({
  plugins: [abilityPlugin],
  /**
   * Assign the modules to the store.
   */
  modules: {
    SAE: {
      namespaced: true,
      modules: {
        document,
        documentFormat,
        documentType,
        view,
      },
    },
    application,
    administrator,
    auth,
    safebox,
    user,
    archivalSpaceUser,
    slip,
    folder,
    metadata,
    conversionRule,
    log,
  },
  /**
   * If strict mode should be enabled.
   */
  strict: debug,
});

export default store;
