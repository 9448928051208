export default {
  views: [],
  rightMenuViews: [],
  pagination: {
    offset: 0,
    limit: 10,
    total: undefined,
    currentPage: undefined,
    totalPages: undefined,
  },
  viewDocuments: [],
  currentView: {},
};
