import SafeBoxApi from '@/api/SafeBoxApi';
import * as types from './types';

export default {
  // Get all safe boxes
  getSafeBoxes({ commit }, params) {
    return SafeBoxApi.findAll(params)
      .then((response) => {
        commit(types.SET_SAFE_BOXES, response.data);
        commit(types.SET_PAGINATION, response.data.pagination);
        return response.data;
      });
  },
  // Create a new safe box
  createSafeBox(context, safeBox) {
    return SafeBoxApi.create(safeBox);
  },
  addUserToSafebox(context, data) {
    return SafeBoxApi.addUser(data);
  },
  updateUserToSafebox(context, data) {
    return SafeBoxApi.updateUser(data);
  },
  deleteUserToSafebox(context, user) {
    return SafeBoxApi.destroyUserToSafebox(user);
  },
  // Update a specific safe box
  updateSafeBox({ commit }, safebox) {
    return SafeBoxApi.update(safebox)
      .then((response) => {
        commit(types.UPDATE_SAFE_BOX, response.data);
        return response;
      });
  },
  // Get a specific safe box
  getSafeBoxById({ commit }, safeBoxId) {
    return SafeBoxApi.findById(safeBoxId)
      .then((response) => {
        commit(types.SET_SAFE_BOX, response.data);
        return response.data;
      });
  },
  // Freeze a specific safe box
  freezeSafeBox({ commit }, safeBoxId) {
    return SafeBoxApi.freeze(safeBoxId).then((response) => {
      commit(types.FREEZE_SAFEBOX, response.data);
      return response.data;
    });
  },
  // Unfreeze a specific safe box
  unFreezeSafeBox({ commit }, safeBoxId) {
    return SafeBoxApi.unfreeze(safeBoxId).then((response) => {
      commit(types.FREEZE_SAFEBOX, response.data);
      return response.data;
    });
  },
  reEncryptAes(context, data) {
    return SafeBoxApi.reEncryptAes(data);
  },
  getEncryptionProgression() {
    return SafeBoxApi.getEncryptionProgression();
  },
};
