import LogApi from '@/api/LogApi';
import download from 'downloadjs';
import cloneDeep from 'lodash.clonedeep';
import * as types from './types';

export default {
  // Get all logs
  getLogs({ commit }, params) {
    return LogApi.findAll(params).then((response) => {
      commit(types.SET_LOGS, response.data.data);
      commit(types.SET_PAGINATION, response.data.pagination);
      return cloneDeep(response.data);
    });
  },

  getLogFiles({ commit }, params) {
    return LogApi.findAllFiles(params).then((response) => {
      commit(types.SET_LOG_FILES, response.data.data);
      commit(types.SET_LOG_FILES_PAGINATION, response.data.pagination);
      return response.data;
    });
  },

  download(context, id) {
    return LogApi.download(id).then((response) => {
      download(response.data, `journal_SAE_${id}.xml`);
    });
  },

  setLogColumns({ commit }, columns) {
    commit(types.SET_LOG_COLUMN, columns);
    localStorage.setItem('log-columns', JSON.stringify(columns));
  },
  checkIntegrity() {
    return LogApi.checkIntegrity();
  },
  checkSafeboxIntegrity(context, safeboxId) {
    return LogApi.checkSafeboxIntegrity(safeboxId);
  },
  checkExhaustivity(context, archivalSpaceId) {
    return LogApi.checkExhaustivity(archivalSpaceId);
  },
  downloadJournalEntry(context, data) {
    return LogApi.downloadJournalEntry(data).then((response) => {
      download(response.data, 'journal_entries_SAE_.json');
    });
  },
};
