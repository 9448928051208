import { HTTP } from '@/http/index';

export default {
  findAll(params) {
    return HTTP.get('/users/archival-spaces', { params });
  },
  findById(ids) {
    return HTTP.get(`/archival-spaces/${ids.archivalSpaceId}/users/${ids.userId}`);
  },
  findUserRolelessById(userId) {
    return HTTP.get(`/users/${userId}`);
  },
  update(user) {
    const alertMessages = {
      errorKey: 'alert.errors.updateUser',
      successKey: 'alert.success.updateUser',
    };
    return HTTP.put(`/users/${user.userId}`, user, { alertMessages });
  },
  updateUserRoleless(user) {
    const alertMessages = {
      errorKey: 'alert.errors.updateUser',
      successKey: 'alert.success.updateUser',
    };
    return HTTP.put(`/users/${user.userId}`, user, { alertMessages });
  },
  destroy(user) {
    let alertMessages = {
      errorKey: 'alert.errors.deleteUser',
      successKey: 'alert.success.deleteUser',
    };
    if (user.action === 'deleted') {
      alertMessages = {};
    }
    // eslint-disable-next-line no-param-reassign
    delete user.action;
    return HTTP.delete(`/archival-spaces/${user.archivalSapceId}/users/${user.userId}`, { alertMessages });
  },
  destroyRolelessUser(user) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteUser',
      successKey: 'alert.success.deleteUser',
    };
    return HTTP.delete(`/users/${user.userId}`, { alertMessages });
  },
  setExpirationInstant(data) {
    const alertMessages = {
      errorKey: 'alert.errors.updatePasswordDuration',
      successKey: 'alert.success.updatePasswordDuration',
    };
    return HTTP.put(
      `/users/${data.userId}/password-expiration-instant`,
      data.duration,
      { alertMessages },
    );
  },
  getDomain() {
    return HTTP.get('/domains');
  },
};
