import * as types from './types';

export default {
  [types.SET_DOCUMENTS](state, documents) {
    state.all = documents;
  },
  // Set documents types in the state
  [types.SET_LOCKED_DOCUMENTS](state, documents) {
    state.locked = documents;
  },
  // Delete a specific document types from state
  [types.SET_DESTRUCTIBLE_DOCUMENTS](state, documents) {
    state.destructible = documents;
  },
  [types.SET_CHECKED_INTEGRITY_DOCS](state, documentsData) {
    state.checkedIntegrityDocs = documentsData;
  },
  // Set pagination in the state
  [types.SET_PAGINATION](state, paginationData) {
    state.pagination = paginationData;
  },
  [types.SET_DESTRUCTIBLE_PAGINATION](state, paginationData) {
    state.destructiblePagination = paginationData;
  },
  [types.SET_DESTRUCTIBLE_LIMIT](state, limit) {
    state.destructiblePagination.limit = limit;
  },
  [types.SET_DOCUMENT](state, document) {
    state.currentDocument = document;
  },
  [types.SET_DOCUMENT_METADATA](state, documentMetadatas) {
    state.documentMetadatas = documentMetadatas;
  },
  [types.SET_LIMIT](state, limit) {
    state.pagination.limit = limit;
  },
};
