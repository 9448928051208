import { HTTP } from '@/http/index';

export default {
  create(documentFormat) {
    const alertMessages = {
      errorKey: 'alert.errors.createDocumentFormat',
      successKey: 'alert.success.createDocumentFormat',
    };
    return HTTP.post('/control-rules', documentFormat, { alertMessages });
  },
  findAll() {
    return HTTP.get('/file-formats');
  },
  destroy(controlRuleID) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteDocumentFormat',
      successKey: 'alert.success.deleteDocumentFormat',
    };
    return HTTP.delete(`/control-rules/${controlRuleID}`, { alertMessages });
  },
};
