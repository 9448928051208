import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import EdocUi from '@silae/edoc-ui';
import { abilitiesPlugin } from '@casl/vue';
import Default from '@/layouts/Default.vue';
import Minimal from '@/layouts/Minimal.vue';
import interceptorsSetup from '@/http/interceptors';
import App from './App.vue';
import router from './router';
import store, { ability } from './store';
import UnsupportedBrowser from './plugins/UnsupportedBrowser';
import i18n from './i18n';
import '@silae/edoc-ui/lib/components/edoc-ui.css';
import '@/assets/scss/main.scss';

Vue.component('default-layout', Default);
Vue.component('minimal-layout', Minimal);

Vue.config.productionTip = false;

Vue.use(UnsupportedBrowser);
Vue.use(abilitiesPlugin, ability);
Vue.use(EdocUi);

sync(store, router);

interceptorsSetup();

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

export default app;
