export default {
  slips: [],
  nbSlipsToValidate: 0,
  nbSlipsFinalized: 0,
  nbDestructibleDocs: 0,
  pagination: {
    offset: 0,
    limit: 10,
    total: undefined,
    currentPage: undefined,
    totalPages: undefined,
  },
};
