import { HTTP } from '@/http';
import Axios from 'axios';

export default {
  findAll(params) {
    return HTTP.get('/document-views', { params });
  },
  getViewsFiltered(params) {
    const searchBody = params.filterObject;
    const { limit, offset } = params.query;
    return HTTP.post(
      '/document-views/search',
      searchBody,
      { params: { limit, offset } },
    );
  },
  getFolders() {
    return HTTP.get('/document-views/folders');
  },
  getDocuments(params) {
    const { limit, offset } = params;
    return HTTP.post(`/documents/views/${params.viewId}`, '', { params: { limit, offset } });
  },
  getDocumentsFiltered(params) {
    const objectFilters = { filters: params.filters };
    return HTTP.post(`/documents/views/${params.viewId}${params.query}`, objectFilters);
  },
  getViewDetail(viewId) {
    return HTTP.get(`/document-views/${viewId}`);
  },
  create(params) {
    const alertMessages = {
      errorKey: 'alert.errors.createView',
      successKey: 'alert.success.createView',
    };
    return HTTP.post('/document-views', params, { alertMessages });
  },
  update(params) {
    const alertMessages = {
      errorKey: 'alert.errors.updateView',
      successKey: 'alert.success.updateView',
    };
    return HTTP.put(`/document-views/${params.viewId}`, params.view, { alertMessages });
  },
  destroy(viewId) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteView',
      successKey: 'alert.success.deleteView',
    };
    return HTTP.delete(`/document-views/${viewId}`, { alertMessages });
  },
  share(view) {
    const sharedView = {
      folderId: view.folderId,
      name: view.name,
      columns: view.columns,
      filters: view.filters,
      ownerUserId: null,
    };
    const alertMessages = {
      errorKey: 'alert.errors.shareView',
      successKey: 'alert.success.shareView',
    };
    return HTTP.put(`/document-views/${view.id}`, sharedView, { alertMessages });
  },
  duplicate(viewId) {
    const alertMessages = {
      errorKey: 'alert.errors.duplicateView',
      successKey: 'alert.success.duplicateView',
    };
    return HTTP.post(`/document-views/${viewId}/duplicate`, { alertMessages });
  },
  onDocumentTypeSearch(documentType) {
    return Axios.get(`${process.env.VUE_APP_API_LOCATION}/document-types/preview?name=${documentType.name}`, {
      headers: {
        Authorization: `Bearer ${documentType.sessionData}`,
      },
    });
  },
  onUserSearch(user) {
    return Axios.get(`${process.env.VUE_APP_API_LOCATION}/users/preview?name=${user.name}`, {
      headers: {
        Authorization: `Bearer ${user.sessionData}`,
      },
    });
  },
  createDocumentViewFolder(payload) {
    return HTTP.post(`/archival-spaces/${payload.id}/document-views/folders`, payload.name);
  },
};
