/* eslint-disable import/prefer-default-export */
export const SET_USERS_DATA = 'SET_USERS_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_ARCHIVAL_SPACE_USERS_DATA = 'SET_ARCHIVAL_SPACE_USERS_DATA';
export const DELETE_USER = 'DELETE_USER';
export const SET_PAGINATION = 'SET_PAGINATION';
export const ADD_SAFEBOX_USERS = 'ADD_SAFEBOX_USERS';
export const REMOVE_SAFE_BOX_USER = 'REMOVE_SAFE_BOX_USER';
export const RESET_SAFE_BOX_USER_STATE = 'RESET_SAFE_BOX_USER_STATE';
export const SET_LIMIT = 'SET_LIMIT';
