import UserApi from '@/api/UserApi';
import { setAuthorization } from '@/http/index';

import * as types from './types';

export default {
  getUsers({ commit }, params) {
    return UserApi.findAll(params).then((response) => {
      commit(types.SET_PAGINATION, response.data.pagination);
      commit(types.SET_USERS_DATA, response.data.data);
      return response.data;
    });
  },

  getUser({ commit }, userId) {
    return UserApi.findById(userId).then((response) => {
      commit(types.SET_USER_DATA, response.data);
      return response.data;
    });
  },
  getUserSelf({ commit }) {
    return UserApi.findByIdSelf().then((response) => {
      commit(types.SET_USER_DATA, response.data);
      return response.data;
    });
  },
  getSafeBoxeUsers({ commit }) {
    return UserApi.findArchivalSpaceUsers().then((response) => {
      commit(types.SET_PAGINATION, response.data.pagination);
      commit(types.SET_ARCHIVAL_SPACE_USERS_DATA, response.data.data);
      return response.data;
    });
  },
  getArchivalSpaceUsers({ commit }, archivalSpaceId) {
    return UserApi.findAllArchivalSpaceUsers(archivalSpaceId).then((response) => {
      commit(types.SET_PAGINATION, response.data.pagination);
      commit(types.SET_USERS_DATA, response.data.data);
      return response.data;
    });
  },
  getRights() {
    return UserApi.getRights().then((response) => response.data);
  },
  createUser(context, userData) {
    return UserApi.create(userData).then((response) => response);
  },
  createUserRights(context, user) {
    return UserApi.createRights(user).then((response) => response);
  },
  updateUserRights(context, user) {
    return UserApi.updateRights(user).then((response) => response);
  },
  updateUser({ commit }, data) {
    return UserApi.update(data).then((response) => {
      if (response.data.id === data.userInfoId) {
        commit('auth/SET_ROLE_DATA', response.data.roles[0], { root: true });
      }
      return response.data;
    });
  },
  updatePassword(context, data) {
    return UserApi.updatePassword(data).then((response) => response.data);
  },
  updatePasswordForOther(context, data) {
    return UserApi.updatePasswordForOther(data).then((response) => response.data);
  },
  createArchivalSpaceUser(context, userData) {
    return UserApi.createArchivalSpaceUser(userData).then((response) => response.data);
  },
  // Delete a specific user
  deleteUser({ commit }, user) {
    return UserApi.destroy(user).then((response) => {
      commit(types.DELETE_USER, user.userId);
      return response.data;
    });
  },
  getRoles({ commit, dispatch }) {
    const sessionString = localStorage.getItem('sae-user-session');
    if (sessionString) {
      const sessionData = JSON.parse(sessionString);
      return UserApi.findRoles(sessionData).then((response) => {
        setAuthorization(sessionData);
        commit('auth/SET_ROLES_DATA', response.data, { root: true });
        return response.data;
      }).catch((error) => {
        if (error.response.status >= 400) {
          dispatch('auth/logout', null, { root: true });
        }
        return Promise.reject(error);
      });
    }
    return Promise.reject();
  },
  // Add a user in safebox user state
  setSafeBoxUsers({ commit }, users) {
    commit(types.ADD_SAFEBOX_USERS, users);
  },
  // Reset safe box user state
  resetSafeBoxUser({ commit }) {
    commit(types.RESET_SAFE_BOX_USER_STATE);
  },
  passwordRedefine(context, user) {
    return UserApi.passwordRedefine(user)
      .then((response) => response).catch((error) => error.response);
  },
  passwordValidity(context, data) {
    return UserApi.passwordValidity(data);
  },
};
