import ArchivalSpaceUserApi from '@/api/ArchivalSpaceUserApi';
import cloneDeep from 'lodash.clonedeep';
import * as types from './types';

export default {
  getUsers({ commit }, params) {
    return ArchivalSpaceUserApi.findAll(params).then((response) => {
      commit(types.SET_PAGINATION, response.data.pagination);
      commit(types.SET_USERS_DATA, response.data.data);
      return response.data;
    });
  },
  getUser({ commit }, ids) {
    return ArchivalSpaceUserApi.findById(ids).then((response) => {
      commit(types.SET_USER_DATA, response.data);
      return cloneDeep(response.data);
    });
  },
  getRolelessUser({ commit }, userId) {
    return ArchivalSpaceUserApi.findUserRolelessById(userId).then((response) => {
      commit(types.SET_USER_DATA, response.data);
      return cloneDeep(response.data);
    });
  },
  createDomainUserRoleless(context, userData) {
    return ArchivalSpaceUserApi.createDomainUserRoleless(userData)
      .then((response) => response);
  },
  updateUser({ commit }, user) {
    return ArchivalSpaceUserApi.update(user).then((response) => {
      commit(types.UPDATE_USER, response.data);
      return response;
    });
  },
  updateRolelessUser({ commit }, user) {
    return ArchivalSpaceUserApi.updateUserRoleless(user).then((response) => {
      commit(types.UPDATE_USER, response.data);
      return response;
    });
  },
  deleteUser({ commit }, user) {
    return ArchivalSpaceUserApi.destroy(user)
      .then((response) => {
        commit(types.DELETE_USER, user.userId);
        return response.data;
      });
  },
  destroyRolelessUser({ commit }, user) {
    return ArchivalSpaceUserApi.destroyRolelessUser(user)
      .then((response) => {
        commit(types.DELETE_USER, user.userId);
        return response.data;
      });
  },
  setExpirationInstant(context, data) {
    return ArchivalSpaceUserApi.setExpirationInstant(data);
  },
  getDomain() {
    return ArchivalSpaceUserApi.getDomain();
  },
};
