import Vue from 'vue';
import VueRouter from 'vue-router';
import store, { ability } from '@/store';
import i18n from '../i18n';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: { name: 'DashboardPage' } },
  {
    path: '/home',
    name: 'DashboardPage',
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
      ],
    },
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Dashboard.vue'),
  },
  {
    path: '/profiles',
    name: 'profiles',
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.profiles.title') },
      ],
    },
    component: () => import(/* webpackChunkName: "administrators" */ '@/pages/profiles/Profile.vue'),
  },
  {
    path: '/administrators',
    name: 'AdministratorsPage',
    meta: {
      requiresAuth: true,
      resource: 'DOMAIN_USER',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.administrators.administrators') },
      ],
    },
    component: () => import(/* webpackChunkName: "administrators" */ '@/pages/administrators/Administrators.vue'),
  },
  {
    path: '/administrators/create',
    name: 'CreateAdmin',
    meta: {
      requiresAuth: true,
      resource: 'DOMAIN_USER',
      action: 'CREATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.administrators.administrators'), link: 'AdministratorsPage' },
        { name: i18n.t('pages.administrators.create') },
      ],
    },
    component: () => import(/* webpackChunkName: "home" */ '@/pages/administrators/Create.vue'),
  },
  {
    path: '/administrators/:userId/edit',
    name: 'UpdateAdministrator',
    meta: {
      requiresAuth: true,
      resource: 'DOMAIN_USER',
      action: 'UPDATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.administrators.administrators'), link: 'AdministratorsPage' },
        { name: i18n.t('pages.administrators.update') },
      ],
    },
    component: () => import(/* webpackChunkName: "home" */ '@/pages/administrators/Update.vue'),
  },
  {
    path: '/safe-boxes',
    name: 'SafeBoxes',
    meta: {
      requiresAuth: true,
      resource: 'ARCHIVAL_SPACE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.safeboxes.title') },
      ],

    },
    component: () => import(/* webpackChunkName: "safebox" */ '@/pages/safeboxes/SafeBoxes.vue'),
  },
  {
    path: '/safe-boxes/create',
    name: 'CreateSafeBoxes',
    meta: {
      requiresAuth: true,
      resource: 'ARCHIVAL_SPACE',
      action: 'CREATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.safeboxes.create.safeBoxes'), link: 'SafeBoxes' },
        { name: i18n.t('pages.safeboxes.create.createSafeBox') },
      ],
    },
    component: () => import(/* webpackChunkName: "safeboxes" */ '@/pages/safeboxes/Create.vue'),
  },
  {
    path: '/safe-boxes/:id/edit',
    name: 'UpdateSafeBox',
    meta: {
      requiresAuth: true,
      resource: 'ARCHIVAL_SPACE',
      action: 'UPDATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.safeboxes.create.safeBoxes'), link: 'SafeBoxes' },
        { name: i18n.t('pages.safeboxes.updateSafeBox') },
      ],
    },
    component: () => import(/* webpackChunkName: "safeboxes" */ '@/pages/safeboxes/Update.vue'),
  },
  {
    path: '/slips/finalized',
    name: 'FinalizedSlips',
    props: {
      status: 'VALIDE',
    },
    meta: {
      requiresAuth: true,
      resource: 'BORDEREAU',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.slips.titleFinalized') },
      ],
    },
    component: () => import(
      /* webpackChunkName: "slips" */ '@/pages/slips/Slips.vue'
    ),
  },
  {
    path: '/slips/needs-validation',
    name: 'NeedsValidationSlips',
    props: {
      status: 'A_VALIDER',
    },
    meta: {
      requiresAuth: true,
      resource: 'BORDEREAU',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.slips.titleNeedsValidation'), link: 'NeedsValidationSlips' },
      ],
    },
    component: () => import(
      /* webpackChunkName: "slips" */ '@/pages/slips/Slips.vue'
    ),
  },
  {
    path: '/slips/:id',
    name: 'SlipDetail',
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        {
          name: [(route) => (route.query.from === 'finalized' ? i18n.t('pages.slips.titleFinalized')
            : i18n.t('pages.slips.titleNeedsValidation'))],
          link: [(route) => (route.query.from === 'finalized' ? 'FinalizedSlips' : 'NeedsValidationSlips')],
        },
        { name: i18n.t('pages.slips.detail') },
      ],
    },
    component: () => import(/* webpackChunkName: "slips" */ '@/pages/slips/SlipDetail.vue'),
  },
  {
    path: '/metadatas',
    name: 'MetadatasPage',
    meta: {
      requiresAuth: true,
      resource: 'METADATA',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.metadatas.title') },
      ],
    },
    component: () => import(
      /* webpackChunkName: "metadatas" */ '@/pages/metadatas/Metadatas.vue'
    ),
  },
  {
    path: '/metadatas/create',
    name: 'CreateMetadata',
    meta: {
      requiresAuth: true,
      resource: 'METADATA',
      action: 'CREATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.metadatas.title'), link: 'MetadatasPage' },
        { name: i18n.t('pages.metadatas.addMetadata') },
      ],
    },
    component: () => import(
      /* webpackChunkName: "metadatas" */ '@/pages/metadatas/Create.vue'
    ),
  },
  {
    path: '/metadatas/:id/edit',
    name: 'UpdateMetadata',
    meta: {
      requiresAuth: true,
      resource: 'METADATA',
      action: 'UPDATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: '/', icon: 'home' },
        { name: i18n.t('pages.metadatas.title'), link: 'MetadatasPage' },
        { name: i18n.t('pages.metadatas.updateMetadata') },
      ],
    },
    component: () => import(
      /* webpackChunkName: "metadatas" */ '@/pages/metadatas/Update.vue'
    ),
  },
  {
    path: '/document-types',
    name: 'DocumentTypes',
    meta: {
      requiresAuth: true,
      resource: 'DOCUMENT_TYPE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.documentTypes.settingDocumentsType') },
      ],
    },
    component: () => import(
      /* webpackChunkName: "document-types" */ '@/pages/documentTypes/DocumentTypes.vue'
    ),
  },
  {
    path: '/document-types/create',
    name: 'CreateDocumentType',
    meta: {
      requiresAuth: true,
      resource: 'DOCUMENT_TYPE',
      action: 'CREATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.documentTypes.settingDocumentsType'), link: 'DocumentTypes' },
        { name: i18n.t('pages.documentTypes.createDocumentType') },
      ],
    },
    component: () => import(/* webpackChunkName: "document-types" */ '@/pages/documentTypes/Create.vue'),
  },
  {
    path: '/document-types/:id/edit',
    name: 'UpdateDocumentType',
    meta: {
      requiresAuth: true,
      resource: 'DOCUMENT_TYPE',
      action: 'UPDATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.documentTypes.settingDocumentsType'), link: 'DocumentTypes' },
        { name: i18n.t('pages.documentTypes.updateDocumentType') },
      ],
    },
    component: () => import(
      /* webpackChunkName: "document-types" */ '@/pages/documentTypes/Update.vue'
    ),
  },
  {
    path: '/users',
    name: 'UsersPage',
    meta: {
      requiresAuth: true,
      resource: 'USERS',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.users.title') },
      ],
    },
    component: () => import(/* webpackChunkName: "users" */ '@/pages/users/Users.vue'),
  },
  {
    path: '/archival-spaces/users',
    name: 'ArchivalSpaceUsers',
    meta: {
      requiresAuth: true,
      resource: 'USERS',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.users.title') },
      ],
    },
    component: () => import(/* webpackChunkName: "users" */ '@/pages/archivalSpaceUsers/Users.vue'),
  },
  {
    path: '/archival-spaces/users/create',
    name: 'CreateArchivalSpaceUser',
    meta: {
      requiresAuth: true,
      resource: 'USER',
      action: 'CREATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.users.title'), link: 'ArchivalSpaceUsers' },
        { name: i18n.t('pages.users.create') },
      ],
    },
    component: () => import(/* webpackChunkName: "users" */ '@/pages/archivalSpaceUsers/Create.vue'),
  },
  {
    path: '/archival-spaces/users/:userId/edit',
    name: 'UpdateArchivalSpaceUser',
    meta: {
      requiresAuth: true,
      resource: 'USER',
      action: 'UPDATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.users.title'), link: 'ArchivalSpaceUsers' },
        { name: i18n.t('pages.users.edit') },
      ],
    },
    component: () => import(/* webpackChunkName: "users" */ '@/pages/archivalSpaceUsers/Update.vue'),
  },
  {
    path: '/users/create',
    name: 'CreateUser',
    meta: {
      requiresAuth: true,
      resource: 'USER',
      action: 'CREATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.users.title'), link: 'UsersPage' },
        { name: i18n.t('pages.users.create') },
      ],
    },
    component: () => import(/* webpackChunkName: "users" */ '@/pages/users/Create.vue'),
  },
  {
    path: '/users/:id/edit',
    name: 'UpdateUser',
    meta: {
      requiresAuth: true,
      resource: 'USER',
      action: 'UPDATE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.users.title'), link: 'UsersPage' },
        { name: i18n.t('pages.users.edit') },
      ],
    },
    component: () => import(/* webpackChunkName: "users" */ '@/pages/users/Update.vue'),
  },
  {
    path: '/document-types/create',
    name: 'Create',
    meta: {
      requiresAuth: true,
      resource: 'DOCUMENT_TYPE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('navigation.folders') },
        { name: i18n.t('navigation.allViews') },
      ],
    },
    component: () => import(/* webpackChunkName: "documents" */ '@/pages/documentTypes/Create.vue'),
  },
  {
    path: '/logs',
    name: 'LogsFilter',
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.logs.title') },
      ],
    },
    component: () => import(/* webpackChunkName: "logs" */ '@/pages/logs/LogsFilter.vue'),
  },
  {
    path: '/log-files',
    name: 'LogFiles',
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.logs.title') },
      ],
    },
    component: () => import(/* webpackChunkName: "logs" */ '@/pages/logs/LogFiles.vue'),
  },
  {
    path: '/folders',
    name: 'FoldersPage',
    meta: {
      requiresAuth: true,
      resource: 'DOCUMENT_VIEW_FOLDER',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('navigation.folders') },
        { name: i18n.t('navigation.allFolders') },
      ],
    },
    component: () => import(/* webpackChunkName: "views" */ '@/pages/folders/Folders.vue'),
  },
  {
    path: '/folders/:folderId',
    name: 'FolderDetail',
    meta: {
      requiresAuth: true,
      resource: 'DOCUMENT_VIEW_FOLDER',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('navigation.folders') },
        { name: i18n.t('navigation.displayAll') },
      ],
    },
    component: () => import(/* webpackChunkName: "views" */ '@/pages/folders/FolderDetail.vue'),
  },
  {
    path: '/views',
    name: 'ViewsPage',
    meta: {
      requiresAuth: true,
      resource: 'DOCUMENT_VIEW',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('navigation.folders') },
        { name: i18n.t('navigation.allViews') },
      ],
    },
    component: () => import(/* webpackChunkName: "views" */ '@/pages/views/Views.vue'),
  },
  {
    path: '/views/:viewId',
    name: 'ViewDetail',
    meta: {
      requiresAuth: true,
      resource: 'DOCUMENT_VIEW',
      action: 'READ',
    },
    component: () => import(/* webpackChunkName: "views" */ '@/pages/views/ViewDetail.vue'),
  },
  {
    path: '/documents/destructibles',
    name: 'DestructibleDocs',
    meta: {
      requiresAuth: true,
      resource: 'DOCUMENT',
      action: 'LIST_DESTRUCTIBLE',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.destructibleDocuments.title') },
      ],
    },
    component: () => import(/* webpackChunkName: "documents" */ '@/pages/documents/DestructibleDocs.vue'),
  },
  {
    path: '/document-formats',
    name: 'DocumentFormats',
    meta: {
      requiresAuth: true,
      resource: 'FILE_FORMAT',
      action: 'LIST',
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('navigation.formatToBeControlled') },
      ],
    },
    component:
      () => import(/* webpackChunkName: "documents" */ '@/pages/documentFormats/documentFormats.vue'),
  },
  {
    path: '/documents/:id/:folderName/:viewName/:docName/:folderId',
    name: 'DocumentPage',
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('navigation.folders'), link: 'FoldersPage' },
        {
          name: [(route) => `${route.params.folderName}`],
          link: 'FolderDetail',
        },
        {
          name: [(route) => `${route.params.viewName}`],
          link: 'ViewDetail',
        },
        { name: [(route) => `${route.params.docName}`, ''] },
      ],
    },
    component: () => import(/* webpackChunkName: "documents" */ '@/pages/documents/Document.vue'),
  },
  {
    path: '/documents/destructibles/:id/:docName',
    name: 'DestructibleDocument',
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('pages.destructibleDocuments.title'), link: 'DestructibleDocs' },
        { name: [(route) => `${route.params.docName}`, ''] },
      ],
    },
    component: () => import(/* webpackChunkName: "documents" */ '@/pages/documents/Document.vue'),
  },
  {
    path: '/documents',
    name: 'DocumentsPage',
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('navigation.folders') },
        { name: i18n.t('navigation.documents') },
      ],
    },
    component: () => import(/* webpackChunkName: "documents" */ '@/pages/documents/Documents.vue'),
  },
  {
    path: '/conversion-rules',
    name: 'ConversionRules',
    meta: {
      resource: 'CONVERSION_RULE',
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('navigation.conversionRules') },
      ],
    },
    component: () => import(/* webpackChunkName: "documents" */ '@/pages/conversionRules/ConversionRules.vue'),
  },
  {
    path: '/conversion-rules/create',
    name: 'CreateConversionRule',
    meta: {
      resource: 'CONVERSION_RULE',
      action: 'CREATE',
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('navigation.conversionRules') },
      ],
    },
    component: () => import(/* webpackChunkName: "documents" */ '@/pages/conversionRules/Create.vue'),
  },
  {
    path: '/advanced-parameters',
    name: 'AdvancedParameters',
    meta: {
      resource: 'DOMAIN',
      action: 'UPDATE',
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('header.settings.advancedParameters') },
      ],
    },
    component: () => import(/* webpackChunkName: "documents" */ '@/pages/archivalSpaceUsers/AdvancedParameters.vue'),
  },
  {
    path: '/advanced-operations',
    name: 'AdvancedOperations',
    meta: {
      resource: 'AESKEY_ARCHIVAL_SPACE',
      action: 'UPDATE',
      requiresAuth: true,
      breadcrumbs: [
        { name: i18n.t('navigation.home'), link: 'DashboardPage', icon: 'home' },
        { name: i18n.t('header.settings.advancedOperations') },
      ],
    },
    component: () => import(/* webpackChunkName: "documents" */ '@/pages/safeboxes/AdvancedOperations.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'minimal' },
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/LoginUser.vue'),
  },
  {
    path: '/forgot',
    name: 'Forgot',
    meta: { layout: 'minimal' },
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/LoginUser.vue'),
  },
  {
    path: '*',
    name: 404,
    meta: { layout: 'minimal' },
    component: () => import('@/pages/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const loggedIn = localStorage.getItem('sae-session');

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next({ name: 'Login' });
  }

  if (typeof store.state.auth.selectedRole === 'undefined') {
    await store.dispatch('auth/check');
  } else {
    await store.dispatch('auth/checkAuthorization');
  }

  if (to.matched.some((record) => record.meta.resource !== undefined
    && ability.cannot(record.meta.action || 'LIST', record.meta.resource))) {
    if (!from.name) {
      next({ name: 'DashboardPage' });
    }
  } else {
    next();
  }
  if (to.meta.breadcrumbs !== undefined) {
    to.meta.breadcrumbs.forEach((item) => {
      if (typeof item.name === 'object' && typeof item.link === 'object') {
        // eslint-disable-next-line no-param-reassign
        item.name[1] = item.name[0](to);
        // eslint-disable-next-line no-param-reassign
        item.link[1] = item.link[0](to);
      } else if (typeof item.name === 'object') {
        // eslint-disable-next-line no-param-reassign
        item.name[1] = item.name[0](to);
      }
    });
    next();
  }
});

export default router;
