import Axios from 'axios';

export const HTTP = Axios.create({
  baseURL: `${process.env.VUE_APP_API_LOCATION}`,
  timeout: process.env.TIMEOUT,
  headers: {
    common: {
      Accept: 'application/json',
    },
  },
});

export function setAuthorization(token) {
  HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function clearAuthorization() {
  delete HTTP.defaults.headers.common.Authorization;
}
