import MetadataApi from '@/api/MetadataApi';
import cloneDeep from 'lodash.clonedeep';
import * as types from './types';

export default {
  // Get all metadatas
  getMetadatas({ commit }, params) {
    return MetadataApi.findAll(params).then((response) => {
      commit(types.SET_METADATAS, response.data.data);
      commit(types.SET_PAGINATION, response.data.pagination);
      return response.data;
    });
  },
  getMetadataFiltered({ commit }, params) {
    return MetadataApi.getMetadataFiltered(params).then((response) => {
      commit(types.SET_METADATAS, response.data.data);
      commit(types.SET_PAGINATION, response.data.pagination);
      return response.data;
    });
  },
  // Create a metadata
  createMetadata({ commit }, metadata) {
    return MetadataApi.create(metadata)
      .then((response) => {
        commit(types.CREATE_METADATA, response.data);
        return response;
      });
  },
  // Update a specific metadata
  updateMetadata({ commit }, metadata) {
    return MetadataApi.update(metadata)
      .then((response) => {
        commit(types.UPDATE_METADATA, response.data);
        return response;
      });
  },
  // Delete a specific metadata
  deleteMetadata({ commit }, metadataId) {
    return MetadataApi.destroy(metadataId)
      .then((response) => {
        commit(types.DELETE_METADATA, metadataId);
        return response.data;
      });
  },
  // Get a specific metadata
  getMetadata({ commit }, metadata) {
    return MetadataApi.findById(metadata).then((response) => {
      commit(types.SET_METADATA, response.data);
      return cloneDeep(response.data);
    });
  },
};
