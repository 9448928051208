import { HTTP } from '@/http/index';

export default {
  create(jsonDoc, files) {
    const formData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const jsonData = new Blob([JSON.stringify(jsonDoc)], {
      type: 'application/json',
    });
    const alertMessages = {
      errorKey: 'alert.errors.createDocument',
      successKey: 'alert.success.createDocument',
    };
    formData.append('json', jsonData);
    const blobFile = new Blob(files, { type: 'octet/stream' });
    formData.append(files[0].name, blobFile, files[0].name);

    return HTTP.post('/documents', formData, { config, alertMessages });
  },
  getDocument(documentId) {
    return HTTP.get(`/documents/${documentId}`);
  },
  findAll(params) {
    return HTTP.get('/documents', { params });
  },
  lock(payload) {
    return HTTP.post('/documents/locks', payload);
  },
  unlock(payload) {
    const alertMessages = {
      successKey: 'alert.success.documentUnfrozen',
    };
    return HTTP.post('/documents/unlock', payload, { alertMessages });
  },
  getDocumentLocks(documentId) {
    return HTTP.get(`/documents/${documentId}/locks`);
  },
  getDestructible(params) {
    return HTTP.get('/documents/destructible', { params });
  },
  getDestructibleFiltered({ filterObject, query }) {
    return HTTP.post('/documents/destructible/search', filterObject, { params: query });
  },
  countDestructible(payload) {
    return HTTP.get('/documents/destructible', payload);
  },
  updateLockReason(documentId, payload) {
    return HTTP.put(`/documents/${documentId}/locks/reason`, payload);
  },
  updateReasonDeletion(documentId, payload) {
    return HTTP.put(`/documents/${documentId}/locks/delete-reason`, payload);
  },
  updateRetentionActiveDate(documentId, payload) {
    return HTTP.put(`/documents/${documentId}/retention-active-date`, payload);
  },
  updateArchivingType(documentId, payload) {
    return HTTP.put(`/documents/${documentId}/archiving-type`, payload);
  },
  checkDocumentIntegrity(data) {
    return HTTP.post('/documents/check', data);
  },
  downloadArchive(documentId, fileId) {
    return HTTP.get(`/documents/${documentId}/files/${fileId}/export/zip`, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/zip' },
    });
  },
  downloadFile(documentId, fileId) {
    return HTTP.get(`/documents/${documentId}/files/${fileId}/download`, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    });
  },
  downloadAsFNTC(data) {
    return HTTP.post('/documents/export/fntc', data, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    });
  },
  downloadAttestation(documentId, fileId) {
    return HTTP.get(`/documents/${documentId}/files/${fileId}/cr`, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    });
  },
  destroy(documentId) {
    return HTTP.delete(`/documents/${documentId}`);
  },
  getDocumentMetadatas(documentId) {
    return HTTP.get(`/documents/${documentId}`);
  },
  updateDocumentMetadata(data) {
    const alertMessages = {};
    if (data.toBeDeleted) {
      alertMessages.errorKey = 'alert.errors.deleteDocumentMetadata';
      alertMessages.successKey = 'alert.success.deleteDocumentMetadata';
    } else {
      alertMessages.errorKey = 'alert.errors.updateDocumentMetadata';
      alertMessages.successKey = 'alert.success.updateDocumentMetadata';
    }
    return HTTP.put(`/documents/${data.documentId}/metadata`, data.metadatas, { alertMessages });
  },
  downloadDocumentMetadata(data) {
    return HTTP.get(`/documents/${data.documentId}/initial-metadata-file`, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    });
  },
  downloadLogs(data) {
    return HTTP.post('/documents/journal-entries/download', data, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/json' },
    });
  },
};
