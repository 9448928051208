import * as types from './types';

export default {
  // Set documents format in the state
  [types.SET_DOCUMENT_FORMATS](state, documentFormats) {
    state.documentFormats = documentFormats;
  },
  // Remove a specific document format from state
  [types.DELETE_DOCUMENT_FORMAT](state, controlRuleId) {
    state.documentFormats = state.documentFormats.filter(
      (documentFormat) => documentFormat.id !== controlRuleId,
    );
  },
};
