import AdministratorApi from '@/api/AdministratorApi';
import UserApi from '@/api/UserApi';
import * as types from './types';

export default {
  // Get all administrators
  getAdministrators({ commit }, params) {
    return AdministratorApi.findAll(params).then((response) => {
      commit(types.SET_PAGINATION, response.data.pagination);
      commit(types.SET_ADMINISTRATORS, response.data.data);
      return response;
    });
  },
  getAdministratorById(context, administratorId) {
    return UserApi.findById(administratorId).then((response) => response.data);
  },
  // Delete an administrator
  deleteAdministrator({ commit }, adminId) {
    return UserApi.destroy(adminId).then((response) => {
      if (response.status === 200) {
        commit(types.DELETE_ADMINISTRATOR, response.data);
      }
    });
  },
  // Get total element of administrator
  getTotalPaginationNumber() {
    return new Promise((resolve, reject) => {
      AdministratorApi.findTotalPaginationNumber()
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.pagination);
          }
          reject(response);
        });
    });
  },
  updateUser({ commit }, user) {
    return AdministratorApi.update(user).then((response) => {
      commit(types.UPDATE_USER, response.data);
      return response;
    });
  },
};
