import * as types from './types';

export default {
  // set logs in the state
  [types.SET_LOGS](state, logs) {
    state.logs = logs;
  },
  // Set pagination in the state
  [types.SET_PAGINATION](state, paginationData) {
    state.pagination = paginationData;
  },

  [types.SET_LOG_FILES](state, logFiles) {
    state.logFiles = logFiles;
  },

  [types.SET_LOG_FILES_PAGINATION](state, paginationData) {
    state.logFilesPagination = paginationData;
  },

  [types.SET_LOG_COLUMN](state, columns) {
    state.columns = columns;
  },
  [types.SET_LIMIT](state, limit) {
    state.pagination.limit = limit;
  },
  [types.SET_LOG_FILES_LIMIT](state, limit) {
    state.logFilesPagination.limit = limit;
  },
};
