import * as types from './types';

export default {
  // to enable or disable loading screen
  startLoading(context) {
    context.commit(types.LOADING_START);
  },
  finishLoading(context) {
    context.commit(types.LOADING_FINISH);
  },
  showAlert(context, alert) {
    context.commit(types.SHOW_ALERT, alert);
  },
  closeAlert(context, alertId) {
    context.commit(types.CLOSE_ALERT, alertId);
  },
  showSelectRoleDialog({ commit }) {
    commit(types.SHOW_SELECT_ROLE);
  },
  closeSelectRoleDialog({ commit }) {
    commit(types.CLOSE_SELECT_ROLE);
  },
  setIsMobile({ commit }) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      commit(types.SET_IS_MOBILE, true);
    } else {
      commit(types.SET_IS_MOBILE, false);
    }
  },
  updateResponsive({ commit }) {
    commit(types.UPDATE_RESPONSIVE, document.documentElement.clientWidth);
  },
};
