import { HTTP } from '@/http/index';

export default {
  findAll(payload) {
    const params = new URLSearchParams(payload.params);
    return HTTP.post(`/journals?${params}`, payload.data);
  },
  findAllFiles(params) {
    return HTTP.get('/journals', { params });
  },
  download(data) {
    return HTTP.get(`/journals/${data}/download`, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/octet-stream' },
    });
  },
  checkIntegrity() {
    return HTTP.post('/journals/check-integrity');
  },
  checkSafeboxIntegrity(safeboxId) {
    return HTTP.post(`/journals/check-integrity/${safeboxId}`);
  },
  checkExhaustivity(archivalSpaceId) {
    return HTTP.post(`/archival-spaces/check-exhaustivity/${archivalSpaceId}`);
  },
  downloadJournalEntry(data) {
    return HTTP.post('/documents/journal-entries/search', data, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/json' },
    });
  },
};
