export default {
  metadatas: [],
  pagination: {
    offset: 0,
    limit: 10,
    total: undefined,
    currentPage: undefined,
    totalPages: undefined,
  },
};
