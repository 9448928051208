import AuthApi from '@/api/AuthApi';
import { setAuthorization, clearAuthorization } from '@/http/index';
import * as types from './types';

export default {
  check({ commit, dispatch }) {
    const sessionString = localStorage.getItem('sae-session');
    const userInfoString = localStorage.getItem('user-info');
    if (sessionString) {
      const perimeterString = localStorage.getItem('sae-perimeter');
      if (perimeterString) {
        return dispatch('user/getRoles', null, { root: true }).then((response) => {
          const sessionData = JSON.parse(sessionString);
          const userInfo = JSON.parse(userInfoString);
          setAuthorization(sessionData);
          const perimeterData = JSON.parse(perimeterString);
          const perimeter = response.find((item) => item.perimeterId === perimeterData);
          commit(types.SET_ROLE_DATA, perimeter);
          commit(types.SET_USER_INFO_STATE, userInfo);
          return response;
        });
      }
      dispatch('application/showSelectRoleDialog', null, { root: true });
    }
    return Promise.resolve();
  },
  // eslint-disable-next-line no-unused-vars
  checkAuthorization(context) {
    const sessionString = localStorage.getItem('sae-session');
    const sessionData = JSON.parse(sessionString);
    setAuthorization(sessionData);
  },
  loginFirstStep({ dispatch }, credentials) {
    return AuthApi.loginFirstStep(credentials)
      .then((response) => {
        dispatch('loginSetState', response);
        return response.data;
      }).catch((error) => {
        const token = error.response.headers['set-authorization'];
        setAuthorization(token);
        return error.response;
      });
  },
  loginSetState({ commit, dispatch }, response) {
    const token = response.headers['set-authorization'];
    setAuthorization(token);
    const userInfo = {
      email: response.data.email,
      firstName: response.data.firstName,
      id: response.data.id,
      lastName: response.data.lastName,
      login: response.data.login,
    };
    commit(types.SET_USER_INFO, userInfo);
    commit(types.SET_USER_INFO_STATE, userInfo);
    commit(types.SET_ROLES_DATA, response.data.roles);
    commit(types.SET_USER_SESSION_DATA, token);
    dispatch('application/showSelectRoleDialog', null, { root: true });
  },
  loginSecondStep({ commit, dispatch }, payload) {
    return AuthApi.loginSecondStep({ perimeterId: payload.perimeterId })
      .then((response) => {
        const token = response.headers['set-authorization'];
        clearAuthorization();
        setAuthorization(token);
        const sessionInfo = { name: response.data.name, id: response.data.id };
        commit(types.SET_SESSION_INFO, sessionInfo);
        commit(types.SET_SESSION_DATA, token);
        commit(types.SET_PERIMETER_DATA, payload.perimeterId);
        commit(types.SET_ROLE_DATA, payload);
        dispatch('application/closeSelectRoleDialog', null, { root: true });
        return response.data;
      });
  },
  logout({ commit }) {
    commit(types.LOGOUT);
  },
};
