import documentFormatApi from '@/api/DocumentFormatApi';
import * as types from './types';

export default {
  // Create a document format
  createDocumentFormat(context, documentFormat) {
    return documentFormatApi.create(documentFormat);
  },
  // Get all documents formats
  getDocumentFormats({ commit }, params) {
    return documentFormatApi.findAll(params)
      .then((response) => {
        commit(types.SET_DOCUMENT_FORMATS, response.data);
        return response.data;
      });
  },
  // Delete a document format
  deleteDocumentFormat({ commit }, controlRuleID) {
    return documentFormatApi.destroy(controlRuleID)
      .then((response) => {
        commit(types.DELETE_DOCUMENT_FORMAT, controlRuleID);
        return response.data;
      });
  },
};
