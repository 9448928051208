import { HTTP } from '@/http/index';

export default {
  loginFirstStep(params) {
    const alertMessages = {
      errorKey: 'alert.errors.login',
    };
    return HTTP.post('/login', params, { alertMessages });
  },
  loginSecondStep(params) {
    return HTTP.patch('/login', params);
  },
};
