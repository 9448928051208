import { HTTP } from '@/http/index';
import Axios from 'axios';

export default {
  findAll(params) {
    return HTTP.get('/users/archival-spaces', { params });
  },
  findById(userId) {
    return HTTP.get(`/users/${userId}`);
  },
  findByIdSelf() {
    return HTTP.get('/users/self');
  },
  findArchivalSpaceUsers() {
    return HTTP.get('/users/archival-spaces');
  },
  findAllArchivalSpaceUsers(archivalSpaceId) {
    return HTTP.get(`users/archival-spaces/${archivalSpaceId}`);
  },
  create(user) {
    const alertMessages = {
      successKey: 'alert.success.createUser',
      errorKey: 'alert.errors.createUser',
    };
    return HTTP.post('/users', user, { alertMessages });
  },
  createRights(user) {
    const alertMessages = {
      errorKey: 'alert.errors.createUser',
      successKey: 'alert.success.createUser',
    };
    return HTTP.post(`/users/${user.id}/roles`, user.roles, { alertMessages });
  },
  updateRights(user) {
    const alertMessages = {
      errorKey: 'alert.errors.updateUser',
      successKey: 'alert.success.updateUser',
    };
    return HTTP.put(`/users/${user.id}/roles/${user.roles.perimeterId}`, user.roles, { alertMessages });
  },
  update(data) {
    const alertMessages = {
      errorKey: 'alert.errors.updateUser',
      successKey: 'alert.success.updateUser',
    };
    return HTTP.put(`/users/${data.id}`, data.user, { alertMessages });
  },
  updatePassword(user) {
    const alertMessages = {
      errorKey: 'alert.errors.updatePassword',
      successKey: 'alert.success.updatePassword',
    };
    return HTTP.put('/users/self/password', user, { alertMessages });
  },
  updatePasswordForOther(user) {
    const alertMessages = {
      errorKey: 'alert.errors.updatePassword',
      successKey: 'alert.success.updatePassword',
    };
    return HTTP.put(`/users/${user.id}/password`, user.password, { alertMessages });
  },
  destroy(user) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteUser',
      successKey: 'alert.success.deleteUser',
    };
    return user.mute ? HTTP.delete(`/users/${user.userId}/roles/${user.perimeterId}`)
      : HTTP.delete(`/users/${user.userId}/roles/${user.perimeterId}`, { alertMessages });
  },
  findRoles(token) {
    return Axios.get(`${process.env.VUE_APP_API_LOCATION}/users/self/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  passwordRedefine(user) {
    const alertMessages = {
      errorKey: 'alert.errors.updatePassword',
      successKey: 'alert.success.updatePassword',
    };
    return HTTP.put('/users/self/change-expired-password', user, { alertMessages });
  },
  passwordValidity(data) {
    const alertMessages = {
      errorKey: 'alert.errors.expirationDuration',
      successKey: 'alert.success.expirationDuration',
    };
    return HTTP.put('/domains/password-validity-duration', data, { alertMessages });
  },
};
