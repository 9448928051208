import * as types from './types';

export default {
  // set metadatas in the state
  [types.SET_METADATAS](state, metadatas) {
    state.metadatas = metadatas;
  },
  // Remove a specific metadata in the state
  [types.DELETE_METADATA](state, metadataId) {
    state.metadatas = state.metadatas.filter(
      (metadata) => metadata.id !== metadataId,
    );
  },
  // Add a metadata in the state
  [types.CREATE_METADATA](state, metadata) {
    state.metadatas.unshift(metadata);
  },
  // Edit a specific metadata in the state
  [types.UPDATE_METADATA](state, metadata) {
    const index = state.metadatas.findIndex((item) => item.id === metadata.id);
    if (index !== -1) {
      state.metadatas.splice(index, 1, metadata);
    }
  },
  // Set pagination in the state
  [types.SET_PAGINATION](state, paginationData) {
    state.pagination = paginationData;
  },
  // Set a metadata in the state
  [types.SET_METADATA](state, metadata) {
    state.metadata = metadata;
  },
  [types.SET_LIMIT](state, limit) {
    state.pagination.limit = limit;
  },
};
