export default {
  destructibleDocs: [],
  currentDocument: {},
  checkedIntegrityDocs: [],
  all: [],
  locked: [],
  destructible: [],
  pagination: {
    offset: 0,
    limit: 10,
    total: undefined,
    currentPage: undefined,
    totalPages: undefined,
  },
  destructiblePagination: {
    offset: 0,
    limit: 10,
    total: undefined,
    currentPage: undefined,
    totalPages: undefined,
  },
  documentMetadatas: {},
};
