export default {
  alerts: [],
  isMobile: false,
  showSelectRoleDialog: false,
  loading: {
    nb: 0,
    isVisible: false,
  },
  responsive: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    only: {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
    },
  },
};
