/* eslint-disable import/prefer-default-export */
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_VIEWS_DATA = 'SET_VIEWS_DATA';
export const SET_RIGHT_MENU_VIEWS = 'SET_RIGHT_MENU_VIEWS';
export const SET_VIEW_DOCUMENTS = 'SET_VIEW_DOCUMENTS';
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const REMOVE_VIEW = 'REMOVE_VIEW';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_VIEW_NAME = 'UPDATE_VIEW_NAME';
export const SET_LIMIT = 'SET_LIMIT';
