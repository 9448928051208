import * as types from './types';

export default {
  [types.SET_CONVERSION_RULES](state, conversionRules) {
    state.conversionRules = conversionRules;
  },
  [types.SET_PAGINATION](state, paginationData) {
    state.pagination = paginationData;
  },
  [types.DELETE_CONVERSION_RULES](state, conversionRuleId) {
    state.conversionRules = state.conversionRules.filter(
      (conversionRule) => conversionRule.id !== conversionRuleId,
    );
  },
  [types.SET_FILES_CONVERTER](state, filesConverter) {
    state.filesConverter = filesConverter;
  },
  [types.SET_FILE_FORMATS](state, fileFormats) {
    state.fileFormats = fileFormats;
  },
};
