import Transformer from './BaseTransformer';

class ViewTransformer extends Transformer {
  /**
   * Method used to transform a fetched view.
   *
   * @returns {Object} The transformed view object
   */
  static fetch(view) {
    return {
      id: view.id,
      name: view.name,
      folderName: view.folderName,
    };
  }

  /**
   * Method used to transform a send view.
   *
   * @returns {Object} The transformed view object.
   */
  static send() {
    return {};
  }
}

export default ViewTransformer;
