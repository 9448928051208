import * as types from './types';

export default {
  [types.SET_USERS_DATA](state, users) {
    state.users = users;
  },
  [types.SET_USER_DATA](state, user) {
    state.user = user;
  },
  [types.SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  [types.UPDATE_USER](state, user) {
    const index = state.users.findIndex((item) => item.id === user.id);
    if (index !== -1) {
      state.users.splice(index, 1, user);
    }
  },
  [types.DELETE_USER](state, userId) {
    state.users = state.users.filter(
      (user) => user.id !== userId,
    );
  },
  [types.SET_LIMIT](state, limit) {
    state.pagination.limit = limit;
  },
};
