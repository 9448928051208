import * as types from './types';

export default {
  [types.SET_ADMINISTRATORS](state, administrators) {
    state.administrators = administrators;
  },
  [types.SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  [types.DELETE_ADMINISTRATOR](state, adminId) {
    state.administrators = state.administrators.filter(
      (administrator) => administrator.id !== adminId,
    );
  },
  // Edit a specific metadata in the state
  [types.UPDATE_USER](state, user) {
    const index = state.administrators.findIndex((item) => item.id === user.id);
    if (index !== -1) {
      state.administrators.splice(index, 1, user);
    }
  },
  [types.SET_LIMIT](state, limit) {
    state.pagination.limit = limit;
  },
};
