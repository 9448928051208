import * as types from './types';

export default {
  // set safeb oxes in the state
  [types.SET_SAFE_BOXES](state, safeboxes) {
    state.safeboxes = safeboxes;
  },
  // Add a safe box in the state
  [types.CREATE_SAFE_BOX](state, safeBox) {
    state.safeboxes.unshift(safeBox);
  },
  // Update a specific safe box in the state
  [types.UPDATE_SAFE_BOX](state, safebox) {
    const index = state.safeboxes.findIndex((item) => item.id === safebox.id);
    if (index !== -1) {
      state.safeboxes.splice(index, 1, safebox);
    }
  },
  // Freeze a specific safe box in the state
  [types.FREEZE_SAFEBOX](state, safebox) {
    const index = state.safeboxes.findIndex((e) => e.id === safebox.id);
    if (index !== -1) {
      state.safeboxes.splice(index, 1, safebox);
    }
  },
  // Unfreeze a specific safe box in the state
  [types.UNFREEZE_SAFEBOX](state, safebox) {
    const index = state.safeboxes.findIndex((e) => e.id === safebox.id);
    if (index !== -1) {
      state.safeboxes.splice(index, 1, safebox);
    }
  },
  // Set pagination in the state
  [types.SET_PAGINATION](state, paginationData) {
    state.pagination = paginationData;
  },
  // Set a safe box  in state
  [types.SET_SAFE_BOX](state, metadata) {
    state.metadata = metadata;
  },
};
