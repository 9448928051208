import download from 'downloadjs';

import DocumentApi from '@/api/DocumentApi';
import * as types from './types';

export default {
  getDocument({ commit }, documentId) {
    return DocumentApi.getDocument(documentId).then((response) => {
      commit(types.SET_DOCUMENT, response.data);
      return response.data;
    });
  },
  getDocuments({ commit }, params) {
    return DocumentApi.findAll(params)
      .then((response) => {
        commit(types.SET_PAGINATION, response.data.pagination);
        commit(types.SET_DOCUMENTS, response.data.data);
        return response.data;
      });
  },
  deleteDocument(context, documentId) {
    return DocumentApi.destroy(documentId).then((response) => response.data);
  },
  // Create a document
  createDocument(context, document) {
    const { jsonDoc, files } = document;
    return DocumentApi.create(jsonDoc, files).then((response) => response.data);
  },
  // Create a lock on document
  lockDocuments(context, payload) {
    return DocumentApi.lock(payload);
  },
  unlockDocuments(context, payload) {
    return DocumentApi.unlock(payload);
  },
  getDocumentLocks({ commit }, documentId) {
    return DocumentApi.getDocumentLocks(documentId)
      .then((response) => {
        commit(types.SET_LOCKED_DOCUMENTS, response.data);
        return response.data;
      });
  },
  getDestructibleDocuments({ commit }, params) {
    return DocumentApi.getDestructible(params).then((response) => {
      commit(types.SET_DESTRUCTIBLE_DOCUMENTS, response.data.data);
      commit(types.SET_DESTRUCTIBLE_PAGINATION, response.data.pagination);
      return response.data;
    });
  },
  getDestructibleFiltered({ commit }, params) {
    return DocumentApi.getDestructibleFiltered(params).then((response) => {
      commit(types.SET_DESTRUCTIBLE_DOCUMENTS, response.data.data);
      commit(types.SET_DESTRUCTIBLE_PAGINATION, response.data.pagination);
      return response.data;
    });
  },
  checkDocumentsIntegrity({ commit }, documentIds) {
    return DocumentApi.checkDocumentIntegrity(documentIds).then((response) => {
      commit(types.SET_CHECKED_INTEGRITY_DOCS, response.data);
      return response.data;
    });
  },
  generateSlip(context, SlipData) {
    return DocumentApi.generateSlip(SlipData)
      .then((response) => response.data);
  },
  updateLockReason(context, payload) {
    return DocumentApi.updateLockReason(payload.documentId, payload.data);
  },
  updateReasonDeletion(context, documentId, payload) {
    return DocumentApi.updateReasonDeletion(documentId, payload);
  },
  updateRetentionActiveDate(context, payload) {
    return DocumentApi.updateRetentionActiveDate(payload.documentId, payload);
  },
  updateArchivingType(context, payload) {
    return DocumentApi.updateArchivingType(payload.documentId, payload.archivingType);
  },
  downloadArchive(context, ids) {
    return DocumentApi.downloadArchive(ids.documentId, ids.file).then((response) => {
      const now = new Date();
      now.setTime(Date.now());
      const date = now.toLocaleString('fr-FR', { month: 'numeric', year: 'numeric', day: 'numeric' });
      download(response.data, `export_eDocSAE_${date.replaceAll('/', '-')} ${now.getHours()}h${now.getMinutes()}.zip`);
    });
  },
  downloadFile(context, document) {
    return DocumentApi.downloadFile(document.documentId, document.file.id).then((response) => {
      download(response.data, document.file.name);
    });
  },
  downloadAsFNTC(context, ids) {
    return DocumentApi.downloadAsFNTC(ids).then((response) => {
      const now = new Date();
      now.setTime(Date.now());
      const date = now.toLocaleString('fr-FR', { month: 'numeric', year: 'numeric', day: 'numeric' });
      download(response.data, `exportFntc_eDocSAE_${date.replaceAll('/', '-')} ${now.getHours()}h${now.getMinutes()}.zip`);
    });
  },
  downloadAttestation(context, param) {
    return DocumentApi.downloadAttestation(param.documentId, param.fileId).then((response) => {
      download(response.data, `attestation-sae-${param.fileName}.pdf`);
    });
  },
  downloadDocumentMetadata(context, data) {
    return DocumentApi.downloadDocumentMetadata(data).then((response) => {
      download(response.data, `fichier-metadonées-sae-${data.name}.json`);
    });
  },
  downloadLogs(context, ids) {
    return DocumentApi.downloadLogs(ids).then((response) => {
      download(response.data, 'journal_SAE.json');
    });
  },
  getDocumentMetadatas({ commit }, documentId) {
    return DocumentApi.getDocumentMetadatas(documentId).then((response) => {
      commit(types.SET_DOCUMENT_METADATA, response.data);
      return response;
    });
  },
  updateDocumentMetadata(context, data) {
    return DocumentApi.updateDocumentMetadata(data);
  },
};
