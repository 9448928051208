import DocumentTypeApi from '@/api/DocumentTypeApi';
import MetadataTransformer from '@/transformers/MetadataTransformer';
import cloneDeep from 'lodash.clonedeep';
import * as types from './types';

export default {
  // Get all document types
  getDocumentTypes({ commit }, params) {
    return DocumentTypeApi.findAll(params).then((response) => {
      commit(types.SET_DOCUMENT_TYPES, response.data.data);
      commit(types.SET_PAGINATION, response.data.pagination);
      return cloneDeep(response.data);
    });
  },
  // Create a document type
  createDocumentType({ commit }, documentType) {
    return DocumentTypeApi.create(documentType)
      .then((response) => {
        commit(types.CREATE_DOCUMENT_TYPE, response.data);
        return response;
      });
  },
  // Delete a document type
  deleteDocumentType({ commit }, documentTypeId) {
    return DocumentTypeApi.destroy(documentTypeId)
      .then((response) => {
        commit(types.DELETE_DOCUMENT_TYPE, documentTypeId);
        return response;
      });
  },
  // Update a document type
  updateDocumentType({ commit }, documentType) {
    return DocumentTypeApi.update(documentType)
      .then((response) => {
        commit(types.UPDATE_DOCUMENT_TYPE, documentType);
        return response;
      });
  },
  // Get a specific document type
  getDocumentTypeById({ commit }, documentType) {
    return DocumentTypeApi.findById(documentType).then((response) => {
      const docType = response.data;
      docType.metadatas = MetadataTransformer.fetchCollection(response.data.metadatas);
      commit(types.SET_DOCUMENT_TYPE, docType);
      return cloneDeep(docType);
    });
  },
  getDocumentTypesFiltered({ commit }, params) {
    return DocumentTypeApi.getDocumentTypesFiltered(params).then((response) => {
      commit(types.SET_DOCUMENT_TYPES, response.data.data);
      commit(types.SET_PAGINATION, response.data.pagination);
      return response.data;
    });
  },
  // Add a document types's metadatas in the state
  setDocumentTypeMetadatas({ commit }, documentTypeMetadatas) {
    commit(types.SET_DOCUMENT_TYPE_METADADAS, documentTypeMetadatas);
  },
  // Remove document type metadata
  removeDocumentTypeMetadatas({ commit }, documentTypeMetadataId) {
    commit(types.REMOVE_DOCUMENT_TYPE_METADATA, documentTypeMetadataId);
  },
  // Reset document type metadatas state
  resetDocumentTypeMetadatas({ commit }) {
    commit(types.RESET_DOCUMENT_TYPE_STATE);
  },
};
