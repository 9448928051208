export const SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES';
export const DELETE_DOCUMENT_TYPE = 'DELETE_DOCUMENT_TYPE';
export const UPDATE_DOCUMENT_TYPE = 'UPDATE_DOCUMENT_TYPE';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE';
export const CREATE_DOCUMENT_TYPE = 'CREATE_DOCUMENT_TYPE';
export const SET_DOCUMENT_TYPE_METADADAS = 'SET_DOCUMENT_TYPE_METADADAS';
export const REMOVE_DOCUMENT_TYPE_METADATA = 'REMOVE_DOCUMENT_TYPE_METADATA';
export const RESET_DOCUMENT_TYPE_STATE = 'RESET_DOCUMENT_TYPE_STATE';
export const SET_LIMIT = 'SET_LIMIT';
