import * as types from './types';

export default {
  [types.SET_FOLDERS_DATA](state, folders) {
    state.folders = folders;
  },
  [types.UPDATE_NAME](state, { folder, index, name }) {
    state.folders.splice(index, 1, Object.assign(folder, { name }));
  },
  [types.DUPLICATE_FOLDER](state, { newFolder, index }) {
    state.folders.splice(index + 1, 0, newFolder);
  },
  [types.SET_CURRENT_FOLDER_DATA](state, folderData) {
    state.currentFolder = folderData;
  },
  [types.SET_CURRENT_FOLDER_PAGINATION](state, paginationData) {
    state.currentFolderPagination = paginationData;
  },
};
