import { HTTP } from '@/http/index';

export default {
  findAll() {
    return HTTP.get('/document-views/folders');
  },
  getFolderViews({ folderId }) {
    return HTTP.get(`/document-views/${folderId}`);
  },
  create(folder) {
    const alertMessages = {
      errorKey: 'alert.errors.createFolder',
      successKey: 'alert.success.createFolder',
    };
    return HTTP.post('/document-views/folders', folder, { alertMessages });
  },
  update(params) {
    const alertMessages = {
      errorKey: 'alert.errors.updateFolder',
      successKey: 'alert.success.updateFolder',
    };
    return HTTP.put(`/document-views/folders/${params.folderId}`, params.folder, { alertMessages });
  },
  delete(folderId) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteFolder',
      successKey: 'alert.success.deleteFolder',
    };
    return HTTP.delete(`/document-views/folders/${folderId}`, { alertMessages });
  },
};
