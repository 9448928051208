import ViewTransformer from '@/transformers/ViewTransformer';
import { setAuthorization } from '@/http';
import ViewApi from '../../../api/ViewApi';
import * as types from './types';

export default {
  getViews({ commit }, params) {
    return ViewApi.findAll(params).then((response) => {
      commit(types.SET_VIEWS_DATA, response.data.data);
      commit(types.SET_PAGINATION, response.data.pagination);
      return JSON.parse(JSON.stringify(response.data));
    });
  },
  getViewsFiltered({ commit }, params) {
    return ViewApi.getViewsFiltered(params).then((response) => {
      commit(types.SET_VIEWS_DATA, response.data.data);
      commit(types.SET_PAGINATION, response.data.pagination);
      return response.data;
    });
  },
  getRightMenuViews({ commit }, params) {
    return ViewApi.findAll(params).then((response) => {
      const views = ViewTransformer.fetchCollection(response.data.data);
      commit(types.SET_RIGHT_MENU_VIEWS, views);
      return response.data;
    });
  },
  getFolders() {
    return ViewApi.getFolders();
  },
  createView(context, params) {
    return ViewApi.create(params);
  },
  updateView(context, params) {
    return ViewApi.update(params).then((response) => response.data);
  },
  getViewDetail({ commit }, viewId) {
    return ViewApi.getViewDetail(viewId).then((response) => {
      commit(types.SET_CURRENT_VIEW, response.data);
      return response;
    });
  },
  getDocuments({ commit }, params) {
    return ViewApi.getDocuments(params).then((response) => {
      commit(types.SET_PAGINATION, response.data.pagination);
      commit(types.SET_VIEW_DOCUMENTS, response.data.data);
      return response.data;
    });
  },
  getDocumentsFiltered({ commit }, params) {
    return ViewApi.getDocumentsFiltered(params).then((response) => {
      commit(types.SET_VIEW_DOCUMENTS, response.data.data);
      return response.data;
    });
  },
  deleteView({ commit }, viewId) {
    return ViewApi.destroy(viewId).then((response) => {
      commit(types.REMOVE_VIEW, viewId);
      return response.data;
    });
  },
  shareView(context, view) {
    return ViewApi.share(view).then((response) => response.data);
  },
  duplicateView(context, viewId) {
    return ViewApi.duplicate(viewId).then((response) => response.data);
  },
  onUserSearch({ dispatch }, docType) {
    const sessionString = localStorage.getItem('sae-session');
    const user = {};
    user.name = docType;
    if (sessionString) {
      user.sessionData = JSON.parse(sessionString);
      return ViewApi.onUserSearch(user).then((response) => {
        setAuthorization(user.sessionData);
        return response.data;
      }).catch((error) => {
        if (error.response.status === 401) {
          dispatch('auth/logout', null, { root: true });
        }
      });
    }
    return Promise.reject();
  },
  onDocumentTypeSearch({ dispatch }, docType) {
    const sessionString = localStorage.getItem('sae-session');
    const documentType = {};
    documentType.name = docType;
    if (sessionString) {
      documentType.sessionData = JSON.parse(sessionString);
      return ViewApi.onDocumentTypeSearch(documentType).then((response) => {
        setAuthorization(documentType.sessionData);
        return response.data;
      }).catch((error) => {
        if (error.response.status === 401) {
          dispatch('auth/logout', null, { root: true });
        }
      });
    }
    return Promise.reject();
  },
  createDocumentViewFolder(context, payload) {
    return ViewApi.createDocumentViewFolder(payload);
  },

};
