import * as types from './types';

export default {
  // change loading state
  [types.LOADING_START](state) {
    state.loading.nb += 1;
    state.loading.isVisible = true;
  },
  [types.LOADING_FINISH](state) {
    state.loading.nb -= 1;
    if (state.loading.nb === 0) {
      state.loading.isVisible = false;
    }
  },
  [types.SHOW_ALERT](state, alert) {
    const alertData = {
      id: state.alerts.length + 1,
      isVisible: true,
      type: alert.type ? alert.type : 'error',
      messages: alert.messages,
      mode: alert.mode ? alert.mode : 'manual',
    };
    state.alerts.push(alertData);
  },
  [types.CLOSE_ALERT](state, id) {
    state.alerts = state.alerts.filter((alert) => alert.id !== id);
  },
  [types.SHOW_SELECT_ROLE](state) {
    state.showSelectRoleDialog = true;
  },
  [types.CLOSE_SELECT_ROLE](state) {
    state.showSelectRoleDialog = false;
  },
  [types.SET_IS_MOBILE](state, value) {
    state.isMobile = value;
  },
  [types.UPDATE_RESPONSIVE](state, docWidth) {
    const [smPoint, mdPoint, lgPoint, xlPoint] = [600, 900, 1200, 1800];
    function between(min, max) {
      return min <= docWidth && docWidth < max;
    }
    Object.assign(state.responsive, {
      xs: docWidth < smPoint,
      sm: docWidth >= smPoint,
      md: docWidth >= mdPoint,
      lg: docWidth >= lgPoint,
      xl: docWidth >= xlPoint,
      only: {
        xs: docWidth < smPoint,
        sm: between(smPoint, mdPoint),
        md: between(mdPoint, lgPoint),
        lg: between(lgPoint, xlPoint),
        xl: docWidth >= xlPoint,
      },
    });
  },
};
