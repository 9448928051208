<template>
  <div class="sae-minimal-layout">
    <div class="sae-alert" style="width: 100%">
      <template v-for="alert in alerts">
        <EuiAlert  :color="alert.type" @click:close="closeAlert(alert.id)"
                    v-if="alert.isVisible" :key="alert.id" :closable="isClosable(alert.mode)"
                    @hook:mounted="autoCloseAlert(alert.mode, alert.id)">
          <EuiList>
            <EuiListItem v-for="(message, index) in alert.messages" :key="index">
              {{ message }}
            </EuiListItem>
          </EuiList>
        </EuiAlert>
      </template>
    </div>
    <SelectRoleDialog v-model="showSelectRoleDialog"/>
    <EuiSimpleLoader :isLoading="isLoading"/>
    <slot/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MinimalLayout',
  components: {
    SelectRoleDialog: () => import(/* webpackPrefetch: true */ '@/components/dialogs/SelectRole.vue'),
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.application.loading.isVisible,
      alerts: (state) => state.application.alerts,
    }),
    showSelectRoleDialog: {
      get() {
        return this.$store.state.application.showSelectRoleDialog;
      },
      set(value) {
        if (value === true) {
          this.$store.commit('application/SHOW_SELECT_ROLE');
        } else {
          this.$store.commit('application/CLOSE_SELECT_ROLE');
        }
      },
    },
  },
  methods: {
    closeAlert(alertId) {
      this.$store.dispatch('application/closeAlert', alertId);
    },
    autoCloseAlert(mode, alertId) {
      if (mode === 'automatic') {
        setTimeout(() => this.closeAlert(alertId), 5000);
      }
    },
    isClosable(mode) {
      return mode !== 'automatic';
    },
  },
};
</script>
