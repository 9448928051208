import { HTTP } from '@/http/index';
import i18n from '@/i18n';
import store from '@/store';

export default function setup() {
  HTTP.interceptors.request.use(
    (config) => {
      store.dispatch('application/startLoading');
      return config;
    },
    (error) => {
      store.dispatch('application/finishLoading');
      return Promise.reject(error);
    },
  );

  HTTP.interceptors.response.use(
    (response) => {
      store.dispatch('application/finishLoading');
      if (response.status < 300) {
        if (response.config.alertMessages && response.config.alertMessages.successKey) {
          store.dispatch('application/showAlert', {
            messages: [`${i18n.t('alert.success.messageStart')} :
            ${i18n.t(response.config.alertMessages.successKey)}`],
            type: 'success',
            mode: 'automatic',
          });
        }
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    },
    (error) => {
      store.dispatch('application/finishLoading');
      if (error.response.status) {
        switch (error.response.status) {
          case 400:
            if (error.config.alertMessages !== undefined
              && Object.prototype.hasOwnProperty.call(error.config.alertMessages, 'errorKey')) {
              store.dispatch('application/showAlert', {
                messages: [`${i18n.t('alert.errors.messageStart')} :
              ${i18n.t(error.config.alertMessages.errorKey)}`],
                type: 'error',
                mode: 'automatic',
              });
            } else if (error.response.data && error.response.data.includes('ArchivalSpace is freezed')) {
              store.dispatch('application/showAlert', {
                messages: [`${i18n.t('alert.errors.messageStart')} :
            ${i18n.t('alert.errors.archiveSpaceIsBlocked')}`],
                type: 'error',
              });
            } else if (error.response.data.data.includes('is being processed')) {
              store.dispatch('application/showAlert', {
                messages: [`${i18n.t('alert.errors.messageStart')} :
              ${i18n.t('alert.errors.needUnlocking')}`],
                type: 'error',
                mode: 'automatic',
              });
            } else if (error.response.data.data.includes('has newRetentionActiveDate')) {
              store.dispatch('application/showAlert', {
                messages: [`${i18n.t('alert.errors.messageStart')} :
              ${i18n.t('alert.errors.invalidRetentionActiveDate')}`],
                type: 'error',
                mode: 'automatic',
              });
            } else if (error.response.data.data.includes('cannot be locked as it is destructible')) {
              store.dispatch('application/showAlert', {
                messages: [`${i18n.t('alert.errors.messageStart')} :
              ${i18n.t('alert.errors.noLockDestructibleDocument')}`],
                type: 'error',
                mode: 'automatic',
              });
            }

            break;
          case 401:
            // Unauthorized
            if (error.response.data.data) {
              if (error.response.data.data.includes('has no roles')) {
                store.dispatch('application/showAlert', {
                  messages: [`${i18n.t('alert.errors.messageStart')} :
                ${i18n.t('alert.errors.userHasNoRole')}`],
                  type: 'error',
                  mode: 'automatic',
                });
              } else if (error.response.data.data.includes('Invalid credentials')) {
                if (error.config.alertMessages !== undefined) {
                  if (error.config.alertMessages.errorKey === 'alert.errors.login') {
                    store.dispatch('application/showAlert', {
                      messages: [`${i18n.t('alert.errors.messageStart')} :
                      ${i18n.t(error.config.alertMessages.errorKey)}`],
                      type: 'error',
                      mode: 'automatic',
                    });
                  } else {
                    store.dispatch('auth/logout');
                  }
                } else {
                  store.dispatch('auth/logout');
                }
              }
            }

            break;
          case 403:
            // Forbidden
            store.dispatch('application/showAlert', {
              messages: [i18n.t('errors.403')],
              type: 'error',
            });
            break;
          case 404:
            // Not Found

            break;
          case 500:
            // Internal Server Error
            store.dispatch('application/showAlert', {
              messages: [`${i18n.t('alert.errors.messageStart')} :
              ${i18n.t('errors.serverErrorPleaseTryAgain')}`],
              type: 'error',
              mode: 'automatic',
            });

            break;
          case 502:
            // Bad Gateway
            break;
          // no default
        }
      }
      return Promise.reject(error);
    },
  );
}
