import { HTTP } from '@/http/index';

export default {
  findAll() {
    return HTTP.get('/conversion-rules');
  },
  destroy(conversionRuleId) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteConversionRule',
      successKey: 'alert.success.deleteConversionRule',
    };
    return HTTP.delete(`/conversion-rules/${conversionRuleId}`, { alertMessages });
  },
  getFilesConverter() {
    return HTTP.get('/file-converters');
  },
  getFileFormats() {
    return HTTP.get('/file-formats');
  },
  create(conversionRule) {
    const alertMessages = {
      errorKey: 'alert.errors.createConversionRule',
      successKey: 'alert.success.createConversionRule',
    };
    return HTTP.post('/conversion-rules', conversionRule, { alertMessages });
  },
};
