import * as types from './types';

export default {
  [types.SET_USERS_DATA](state, users) {
    state.users = users;
  },
  [types.SET_ARCHIVAL_SPACE_USERS_DATA](state, archivalSpaceUsers) {
    state.archivalSpaceUsers = archivalSpaceUsers;
  },
  [types.SET_USER_DATA](state, user) {
    state.currentUser = user;
  },
  [types.DELETE_USER](state, userId) {
    state.users = state.users.filter((e) => e.id !== userId);
  },
  [types.SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  // Set user in safeboxUsers  state
  [types.ADD_SAFEBOX_USERS](state, users) {
    state.safeboxUsers = users;
  },
  // Remove a specific safebox user from state
  [types.REMOVE_SAFE_BOX_USER](state, userId) {
    state.safeboxUsers = state.safeboxUsers.filter(
      (user) => user.id !== userId,
    );
  },
  // // Reset safe box user state
  [types.RESET_SAFE_BOX_USER_STATE](state) {
    state.safeboxUsers = [];
  },
  [types.SET_LIMIT](state, limit) {
    state.pagination.limit = limit;
  },
};
