import * as types from './types';

export default {
  [types.SET_SESSION_DATA](state, sessionData) {
    localStorage.setItem('sae-session', JSON.stringify(sessionData));
  },
  [types.SET_USER_INFO](state, userInfo) {
    localStorage.setItem('user-info', JSON.stringify(userInfo));
  },
  [types.SET_SESSION_INFO](state, sessionInfo) {
    localStorage.setItem('session-info', JSON.stringify(sessionInfo));
  },
  [types.SET_USER_SESSION_DATA](state, sessionData) {
    localStorage.setItem('sae-user-session', JSON.stringify(sessionData));
  },
  [types.SET_PERIMETER_DATA](state, perimeter) {
    localStorage.setItem('sae-perimeter', JSON.stringify(perimeter));
  },
  [types.SET_USER_INFO_STATE](state, userInfo) {
    state.userInfo = userInfo;
  },
  [types.SET_ROLE_DATA](state, role) {
    state.selectedRole = role;
  },
  [types.SET_ROLES_DATA](state, roles) {
    state.roles = roles;
  },
  [types.LOGOUT]() {
    localStorage.clear();
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  },
};
