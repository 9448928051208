export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_CHECKED_INTEGRITY_DOCS = 'SET_CHECKED_INTEGRITY_DOCS';
export const SET_LOCKED_DOCUMENTS = 'SET_LOCKED_DOCUMENTS';
export const SET_DESTRUCTIBLE_DOCUMENTS = 'SET_DESTRUCTIBLE_DOCUMENTS';
export const SET_DESTRUCTIBLE_PAGINATION = 'SET_DESTRUCTIBLE_PAGINATION';
export const SET_DESTRUCTIBLE_LIMIT = 'SET_DESTRUCTIBLE_LIMIT';
export const SET_DOCUMENT_METADATA = 'SET_DOCUMENT_METADATA';
export const SET_LIMIT = 'SET_LIMIT';
