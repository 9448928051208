import { HTTP } from '@/http/index';

export default {
  findAll(params) {
    return HTTP.get('/users', { params });
  },
  findTotalPaginationNumber() {
    return HTTP.get('/users');
  },
  create(domainId, user) {
    const alertMessages = {
      errorKey: 'alert.errors.createAdministrator',
      successKey: 'alert.success.createAdministrator',
    };
    return HTTP.post(`/domains/${domainId}/users`, {
      id: user.id,
      login: user.login,
      password: user.password,
    }, { alertMessages });
  },
  destroy(user) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteAdministrator',
      successKey: 'alert.success.deleteAdministrator',
    };
    return HTTP.delete(`/users/${user.userId}/roles/${user.perimeterId}`, { alertMessages });
  },
  update(data) {
    const alertMessages = {
      errorKey: 'alert.errors.updateUser',
      successKey: 'alert.success.updateUser',
    };
    return HTTP.put(`/users/${data.id}`, data, { alertMessages });
  },
};
