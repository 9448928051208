import FolderApi from '@/api/FolderApi';
import * as types from './types';

export default {
  getFolders({ commit }) {
    return FolderApi.findAll().then((response) => {
      commit(types.SET_FOLDERS_DATA, response.data);
      return response.data;
    });
  },
  getFolderViews({ commit }, params) {
    return FolderApi.getFolderViews(params).then((response) => {
      let folderData = {};
      if (response.data.data.length) {
        folderData = {
          id: response.data.data[0].folderId,
          name: response.data.data[0].folderName,
          views: response.data.data,
        };
      } else {
        folderData = {
          id: null,
          name: null,
          views: response.data.data,
        };
      }
      const paginationData = response.data.pagination;
      commit(types.SET_CURRENT_FOLDER_DATA, folderData);
      commit(types.SET_CURRENT_FOLDER_PAGINATION, paginationData);
      return response.data;
    });
  },
  createFolder(context, folder) {
    return FolderApi.create(folder);
  },
  updateFolder(context, params) {
    return FolderApi.update(params).then((response) => response.data);
  },
  deleteFolder(context, folderId) {
    return FolderApi.delete(folderId).then((response) => response.data);
  },
};
