import * as types from './types';

export default {
  [types.SET_VIEWS_DATA](state, viewsData) {
    state.views = viewsData;
  },
  [types.SET_RIGHT_MENU_VIEWS](state, viewsData) {
    state.rightMenuViews = viewsData;
  },
  [types.REMOVE_VIEW](state, viewId) {
    state.views = state.views.filter((e) => e.id !== viewId);
  },
  [types.SET_PAGINATION](state, paginationData) {
    state.pagination = paginationData;
  },
  [types.SET_CURRENT_VIEW](state, currentView) {
    state.currentView = currentView;
  },
  [types.SET_VIEW_DOCUMENTS](state, documents) {
    state.viewDocuments = documents;
  },
  [types.UPDATE_NAME](state, { view, index, name }) {
    state.views.splice(index, 1, Object.assign(view, { name }));
  },
  [types.UPDATE_VIEW_NAME](state, { name }) {
    state.currentView.name = name;
  },
  [types.SET_LIMIT](state, limit) {
    state.pagination.limit = limit;
  },
};
