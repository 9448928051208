import { HTTP } from '@/http/index';

export default {
  create(documentType) {
    const alertMessages = {
      errorKey: 'alert.errors.createDocumentType',
      successKey: 'alert.success.createDocumentType',
    };
    return HTTP.post('/document-types', documentType, { alertMessages });
  },
  findAll(params) {
    return HTTP.get('/document-types', { params });
  },
  update(documentType) {
    const alertMessages = {
      errorKey: 'alert.errors.updateDocumentType',
      successKey: 'alert.success.updateDocumentType',
    };
    return HTTP.put(`/document-types/${documentType.id}`, documentType, { alertMessages });
  },
  destroy(documentId) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteDocumentType',
      successKey: 'alert.success.deleteDocumentType',
    };
    return HTTP.delete(`/document-types/${documentId}`, { alertMessages });
  },
  findById(documentTypeId) {
    return HTTP.get(`/document-types/${documentTypeId}`);
  },
  getDocumentTypesFiltered(params) {
    const searchBody = params.filterObject;
    const { limit } = params.query;
    const { offset } = params.query;
    return HTTP.post(
      '/document-types/search',
      searchBody,
      { params: { limit, offset } },
    );
  },
};
