import Transformer from './BaseTransformer';

class MetadataTransformer extends Transformer {
  /**
   * Method used to transform a fetched metadata.
   *
   * @returns {Object} document types metadta An metadata object.
   */
  static fetch(metadata) {
    return {
      id: metadata.metadataId,
      name: metadata.metadataName,
      valueType: metadata.metadataValueType,
      externalCode: metadata.metadataExternalCode,
      mandatory: metadata.mandatory,
      active: metadata.active,
      order: metadata.order,
    };
  }

  /**
   * Method used to transform a send metadata.
   *
   * @returns {Object} The transformed document types metadata.
   */
  static send(metadata) {
    return {
      id: metadata.metadataId,
      name: metadata.metadataName,
      valueType: metadata.metadataValueType,
      externalCode: metadata.metadataExternalCode,
      mandatory: metadata.mandatory,
      active: metadata.active,
      order: metadata.order,
    };
  }
}

export default MetadataTransformer;
