<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

const defaultLayout = 'default';

export default {
  computed: {
    ...mapState({
      responsive: (state) => state.application.responsive,
    }),
    layout() {
      return this.$route.name ? `${this.$route.meta.layout || defaultLayout}-layout` : '';
    },
  },
  methods: {
    ...mapActions({
      updateResponsive: 'application/updateResponsive',
    }),
    onResize() {
      this.updateResponsive();
      document.querySelector(':root').style
        .setProperty('--vh', `${window.innerHeight / 100}px`);
    },
  },
  mounted() {
    this.onResize();
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>
